import axios from "axios"
import type {
  UsePostProperties,
  UsePostReturnType,
} from "shared/services/interfaces"
import type { Key } from "swr"
import type { SWRMutationConfiguration } from "swr/mutation"
import useSWRMutation from "swr/mutation"

import { useAuth } from "../auth/useAuth"

type AxiosResponseType =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream"

export function usePost<T, B extends Key = undefined>(
  url: string,
  options?: UsePostProperties<B> & {
    responseType?: AxiosResponseType
    isPublic?: boolean
  },
): UsePostReturnType<T, B> {
  const {
    shouldSkip,
    shouldThrowOnError,
    body,
    contentType = "application/json",
    responseType = "json",
    isPublic = false,
  } = options || {}

  const {
    authCurrentUser,
    isPending,
    isAuthenticated,
    error: userError,
  } = useAuth()

  const fetcher = async (url: string, { arg }: { arg: B }) => {
    if (shouldSkip) return
    if (!isPublic && !isAuthenticated) return
    const headers: Record<string, string> = {
      "Content-Type": contentType,
    }

    if (!isPublic) {
      const { idToken } = await authCurrentUser()
      if (shouldSkip || !idToken) return null
      headers["Authorization"] = idToken
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${url}`,
      arg || body,
      {
        headers,
        responseType,
      },
    )

    if (shouldThrowOnError && response.status !== 200) {
      throw new Error(response.statusText)
    }

    return response.data
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    shouldSkip || (!isPublic && !isAuthenticated) ? null : url,
    fetcher,
  )
  const hasError = !!(userError || error)

  return {
    data,
    isLoading: isMutating || isPending,
    hasError,
    mutate: (
      triggerBody?: B,
      options?: SWRMutationConfiguration<T, string, B>,
    ) =>
      (trigger as any)(
        triggerBody as (null | undefined) & B,
        options as SWRMutationConfiguration<any, any, B, string, any>,
      ),
  }
}

import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

export const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_BUILD_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Feedback({
      colorScheme: "light",
      showBranding: false,
      isEmailRequired: true,
      showName: false,
      buttonLabel: "Help",
      successMessageText: "Merci !",
      cancelButtonLabel: "Annuler",
      submitButtonLabel: "Soumettre",
      formTitle: "Signaler un bug",
      messagePlaceholder: "Quel est le problème ? Qu'attendiez-vous ?",
      themeLight: {
        submitBackground: "#1c2d37",
        submitBackgroundHover: "#1c2d37FA",
      },
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to X.X to capture X%
  // of transactions for performance monitoring.
  tracesSampleRate: parseInt(
    process.env.REACT_APP_TRACE_PROPAGATION_TARGETS || "0",
  ),

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https?:\/\/([a-z]*\.)?stabbl\.com/],
  // Capture Replay for X% of all sessions,
  replaysSessionSampleRate: parseInt(
    process.env.REACT_APP_REPLAYS_SESSION_SAMPLE_RATE_VALUE || "0",
  ),
  // plus for 100% of sessions with an error
  replaysOnErrorSampleRate: parseInt(
    process.env.REACT_APP_REPLAYS_ON_ERROR_SAMPLE_RATE || "1",
  ),
}

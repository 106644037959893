import { useLayoutEffect } from "react"

import type { ApplicationLayoutComponentEnum } from "./ApplicationLayoutContext"
import { useApplicationLayoutContext } from "./useApplicationLayoutContext"

export function useRegisterLayoutComponents(
  componentKey: ApplicationLayoutComponentEnum,
): void {
  const { registerComponent, unregisterComponent } =
    useApplicationLayoutContext()

  useLayoutEffect(() => {
    if (componentKey != null) {
      registerComponent(componentKey)
      return () => unregisterComponent(componentKey)
    }
  }, [componentKey, registerComponent, unregisterComponent])
}

import type {
  Client,
  UsePostProperties,
  UsePostReturnType,
} from "shared/services/interfaces"

import { usePost } from "../core/usePost"

interface UseCreateClientVariables extends Omit<Client, "createdAt" | "id"> {}

export type UseCreateClientProperties =
  UsePostProperties<UseCreateClientVariables>

export function useCreateClient(
  options?: UseCreateClientProperties,
): UsePostReturnType<Client, UseCreateClientVariables> {
  return usePost<Client, UseCreateClientVariables>(`client`, options)
}

import "./Checkbox.scss"

import classNames from "classnames"
import type { ReactElement } from "react"
import { IconCheck } from "shared/assets/icons"

import { Icon } from "../Icon/Icon"

export interface CheckboxProps {
  isChecked: boolean
  isDisabled?: boolean
  className?: string
  onChange: () => void
  name?: string
}

export function Checkbox({
  isChecked,
  isDisabled,
  className,
  name,
  onChange,
}: CheckboxProps): ReactElement {
  const checkboxClass = classNames("Checkbox", className, {
    "Checkbox--checked": isChecked,
    "Checkbox--disabled": isDisabled,
  })

  return (
    <span className={checkboxClass}>
      <input
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        className="Checkbox__nativeInput"
        name={name}
        onChange={onChange}
      />
      {isChecked ? (
        <Icon size={8} color="white-base" icon={<IconCheck />} />
      ) : null}
    </span>
  )
}

import "./Footer.scss"

import classNames from "classnames"
import type { ReactElement } from "react"
import { useApplicationLayoutContext } from "shared/contexts/ApplicationLayout/useApplicationLayoutContext"

export function Footer(): ReactElement {
  const { components } = useApplicationLayoutContext()

  const footerClassnames = classNames("Footer", {
    "Footer--with-right-pannel": components.RIGHT_PANEL,
  })
  return (
    <footer className={footerClassnames}>
      <div className="Footer__links Footer__links--bold"></div>
      <div className="Footer__links">
        <a
          href="/legal-mentions-latest.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mentions Légales
        </a>
        <a
          href="/privacy-policy-latest.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Politique de confidentialité
        </a>
        <a href="/cgu-latest.pdf" target="_blank" rel="noopener noreferrer">
          Conditions générales d'utilisation
        </a>
        <a href="/cgv-latest.pdf" target="_blank" rel="noopener noreferrer">
          Conditions générales de vente
        </a>
      </div>
      <span className="Footer__build">
        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION} - Build{" "}
        {process.env.REACT_APP_BUILD}
      </span>
    </footer>
  )
}

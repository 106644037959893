import "./LogoField.scss"

import type { ReactElement } from "react"
import { useState } from "react"
import { Field } from "react-final-form"
import { LabelledCheckbox } from "shared/components/LabelledCheckbox/LabelledCheckbox"
import { useUpdateUserLogo } from "shared/services/hooks/user/useUpdateUserLogo"

import { Dropzone } from "./_internals/Dropzone/Dropzone"

interface LogoFieldProps {
  name: string
  logo?: string
}

export function LogoField({ name, logo }: LogoFieldProps): ReactElement {
  const [isChecked, setIsChecked] = useState(!!logo)
  const [logoSrc, setLogoSrc] = useState(logo)

  const { mutate: uploadLogo, isLoading: isUploadingLogo } = useUpdateUserLogo()

  const fileToBuffer = async (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => resolve(reader.result as ArrayBuffer)
      reader.onerror = () =>
        reject(new Error("Erreur lors de la lecture du fichier"))
    })
  }

  return (
    <Field name={name}>
      {props => {
        const removeFile = () => {
          props.input.onChange(null)
          setLogoSrc("")
        }

        const handleChange = () => {
          setIsChecked(isPreviousChecked => !isPreviousChecked)
          if (isChecked) {
            removeFile()
          }
        }

        const uploadFile = async (file: File | null) => {
          if (file) {
            const buffer = await fileToBuffer(file)
            uploadLogo(buffer, {
              onSuccess: response => {
                props.input.onChange(response)
                setLogoSrc(response)
              },
              onError: response => {
                console.error(response)
                removeFile()
              },
            })
          }
        }

        return (
          <div>
            <LabelledCheckbox isChecked={isChecked} onChange={handleChange}>
              Ajouter un logo
            </LabelledCheckbox>

            {isChecked && (
              <>
                <Dropzone
                  previewSrc={logoSrc}
                  fileType={"image/*"}
                  isUploading={isUploadingLogo}
                  maxSize={2 * 1024 * 1024} // 2mo
                  onFileChange={uploadFile}
                />
              </>
            )}
          </div>
        )
      }}
    </Field>
  )
}

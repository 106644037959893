import "./Spinner.scss"

import type { ReactNode } from "react"

export interface SpinnerProps {
  size?: number
}

export function Spinner({ size = 32 }: SpinnerProps): ReactNode {
  return (
    <div
      className="Spinner"
      style={{
        width: size,
        minWidth: size,
        height: size,
        minHeight: size,
      }}
    >
      <svg
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        className="Spinner__circle"
      >
        <circle
          fill="none"
          strokeWidth="8"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="28"
        />
      </svg>
    </div>
  )
}

import { type ReactElement } from "react"
import { useFormState } from "react-final-form"
import type { CreateDocumentFormValues } from "sections/documents/pages/CreateDocumentPage/CreateDocumentPageView"
import { AddressField } from "shared/components/FormInputs/AddressField"
import { EmailField } from "shared/components/FormInputs/EmailField"
import { LogoField } from "shared/components/FormInputs/LogoField/LogoField"
import { PhoneField } from "shared/components/FormInputs/PhoneField"
import { SiretField } from "shared/components/FormInputs/SiretField"
import { TogglableInput } from "shared/components/FormInputs/TogglableInput/TogglableInput"
import { UserCompanyField } from "shared/components/FormInputs/UserCompanyField/UserCompanyField"
import { FormSection } from "shared/components/FormSection/FormSection"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import type { UserCompany } from "shared/services/interfaces"

interface InformationFormProps {
  id?: string
  isExpanded?: boolean
  handleFormFocus?: () => void
}

export function InformationsForm({
  id,
  isExpanded,
  handleFormFocus,
}: InformationFormProps): ReactElement {
  const { values } = useFormState<CreateDocumentFormValues>()
  const { userCompany } = values
  const { isAuthenticated } = useAuth()

  const requiredFields: (keyof UserCompany)[] = [
    "name",
    "postalAddress",
    "siret",
  ]
  const completedFieldsCount = requiredFields.reduce((count, fieldName) => {
    if (userCompany && fieldName in userCompany && userCompany[fieldName]) {
      return count + 1
    }
    return count
  }, 0)

  return (
    <FormSection
      id={id}
      title="Mes informations"
      progress={{
        totalFieldsCount: requiredFields.length,
        completedFieldsCount: completedFieldsCount,
      }}
      isExpanded={isExpanded}
      handleFormFocus={handleFormFocus}
    >
      <UserCompanyField
        name="userCompany.name"
        fieldName="userCompany"
        value={userCompany?.name}
      />
      <AddressField
        name="userCompany.postalAddress"
        value={userCompany?.postalAddress}
      />
      <SiretField name="userCompany.siret" value={userCompany?.siret} />
      <EmailField name="userCompany.email" value={userCompany?.email} />
      <PhoneField name="userCompany.phone" value={userCompany?.phone} />
      <TogglableInput
        name="userCompany.vatNumber"
        checkboxLabel="J'applique la TVA"
        checkboxName="userCompany.applyVAT"
        inputLabel="Numero de TVA"
      />
      <TogglableInput
        name="userCompany.rcsRegistration"
        checkboxLabel="Je suis immatriculé au RCS"
        checkboxName="userCompany.hasRCS"
        inputLabel="Numero RCS"
      />
      {isAuthenticated ? (
        <LogoField name="userCompany.logo" logo={userCompany?.logo} />
      ) : null}
    </FormSection>
  )
}

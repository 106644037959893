import type {
  Clients,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"

import { useGet } from "../core/useGet"

export function useGetClients(
  options: UseGetProperties = {},
): UseGetReturnType<Clients> {
  return useGet(`clients`, options)
}

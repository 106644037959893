import { type ReactElement } from "react"
import { Field } from "react-final-form"
import { Input } from "shared/components/Input/Input"

interface ArticleUnitFieldProps {
  name: string
  value?: string
  className?: string
}

export function ArticleUnitField({
  name,
  value,
  className,
}: ArticleUnitFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Input
          className={className}
          label="Unité"
          name={props.input.name}
          value={props.input.value || value}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

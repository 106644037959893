import type { ReactElement } from "react"
import { AuthRouter } from "sections/auth/AuthRouter"
import { ClientsRouter } from "sections/clients/ClientsRouter"
import { DocumentsRouter } from "sections/documents/DocumentsRouter"
import { HomeRouter } from "sections/home/HomeRouter"
import { PlanRouter } from "sections/plans/PlansRouter"
import { UserRouter } from "sections/user/UserRouter"

export function AppRouter(): ReactElement {
  return (
    <>
      <HomeRouter />
      <AuthRouter />

      <DocumentsRouter />
      <ClientsRouter />
      <PlanRouter />
      <UserRouter />
    </>
  )
}

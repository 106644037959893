import "./Input.scss"

import classNames from "classnames"
import { useSelect } from "downshift"
import type {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  HTMLInputTypeAttribute,
  ReactElement,
  ReactNode,
} from "react"
import { useRef, useState } from "react"

import { DropdownList } from "../DropdownList/DropdownList"
import { Icon } from "../Icon/Icon"

interface InputProps<S> {
  label: string
  value: string
  name?: string
  type?: HTMLInputTypeAttribute
  className?: string
  isDisabled?: boolean
  hasError?: boolean
  style?: CSSProperties
  icon?: ReactElement
  suggestions?: S[]
  getSuggestionValue?: (suggestion: S) => string
  getSuggestionId?: (suggestion: S) => string
  renderSuggestion?: (suggestion: S) => ReactNode
  onSelectSuggestion?: (value: S) => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void
  onFocus?: (event?: FocusEvent<HTMLInputElement>) => void
  inputRef?: React.LegacyRef<HTMLInputElement> | null
  readOnly?: boolean
}

export function Input<S = undefined>({
  className,
  isDisabled,
  hasError,
  label,
  style,
  value,
  icon,
  name,
  type = "text",
  suggestions = [],
  renderSuggestion,
  getSuggestionValue = s => s?.toString() ?? "",
  getSuggestionId = s => s?.toString() ?? "",
  onSelectSuggestion = () => {},
  onBlur,
  onFocus,
  onChange,
  readOnly,
}: InputProps<S>): ReactElement {
  const referenceElementRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [isFocused, setFocused] = useState(false)
  const isFilled = !!value

  const itemToString = (item: S | null): string => {
    if (!item) return ""
    return getSuggestionValue(item)
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useSelect({
    items: suggestions,
    itemToString,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onSelectSuggestion(selectedItem)
        closeMenu()
      }
    },
  })

  const inputClass = classNames("Input__container", className, {
    "Input__container--default": !isDisabled && !hasError,
    "Input__container--focused": value || isFocused,
    "Input__container--default-filled":
      !hasError && !isDisabled && isFilled && !isFocused,
    "Input__container--error": hasError && !isDisabled,
    "Input__container--disabled": isDisabled,
  })

  const labelClass = classNames({
    "Input__label--focused": value || isFocused,
    "Input__label--default": !value && !isFocused,
    "Input__label--disabled":
      (!hasError && !isDisabled && !isFocused) || isDisabled,
  })

  const isMenuOpen = isOpen && suggestions.length > 0

  const referenceElementProps = getToggleButtonProps({
    ref: referenceElementRef,
  })
  const menuElementProps = getMenuProps()

  const handleFocus = (
    event: FocusEvent<HTMLInputElement, Element> | undefined,
  ) => {
    setFocused(true)
    onFocus && onFocus(event)
  }

  const handleBlur = (
    event: FocusEvent<HTMLInputElement, Element> | undefined,
  ) => {
    setFocused(false)
    onBlur && onBlur(event)
  }

  return (
    <div className={inputClass} style={style} {...referenceElementProps}>
      <label className={labelClass}>{label}</label>
      <input
        ref={inputRef}
        type={type}
        value={value}
        name={name}
        disabled={isDisabled}
        autoComplete="new-password" // off is not enough for others browsers than Chrome
        data-1p-ignore={true} // 1p workaround
        data-lpignore={true} // dashlane workaround
        data-form-type={true} // dashlane workaround
        readOnly={readOnly}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={e => {
          if (e.key === " ") {
            e.stopPropagation() // Prevent downshift from catching space as a submit
          }
        }}
      />

      {icon ? (
        <Icon color="grey-mid" size={12} icon={icon} className="Input__icon" />
      ) : null}

      <div {...menuElementProps}>
        {isMenuOpen ? (
          <DropdownList referenceElementRef={referenceElementRef}>
            {suggestions.map((item, index) => {
              const itemProps = getItemProps({
                item,
                index,
              })
              return (
                <DropdownList.Item
                  key={getSuggestionId(item)}
                  isHighlighted={highlightedIndex === index}
                  {...itemProps}
                >
                  {renderSuggestion
                    ? renderSuggestion(item)
                    : getSuggestionValue(item)}
                </DropdownList.Item>
              )
            })}
          </DropdownList>
        ) : null}
      </div>
    </div>
  )
}

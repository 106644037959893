import type { UserData } from "aws-sdk/clients/ec2"
import type {
  UseDeleteProperties,
  UseDeleteReturnType,
} from "shared/services/interfaces"

import { useDelete } from "../core/useDelete"

export function useDeleteUser(
  options: UseDeleteProperties = {},
): UseDeleteReturnType<UserData> {
  return useDelete("user", options)
}

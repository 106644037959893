import "./Button.scss"

import classNames from "classnames"
import { type MouseEvent, type ReactElement, useId } from "react"
import { Tooltip } from "react-tooltip"

import { Icon } from "../Icon/Icon"

export type ButtonVariant = "outlined" | "subtle" | "link"
export type ButtonColor = "danger" | "grey"
export type ButtonIconPosition = "left" | "right"

const iconColorMapping: Record<ButtonVariant, string> = {
  outlined: "primary-base",
  subtle: "primary-base",
  link: "primary-base",
}

export interface ButtonProps {
  children?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>
  className?: string
  isDisabled?: boolean
  variant?: ButtonVariant
  color?: ButtonColor
  icon?: ReactElement
  iconPosition?: ButtonIconPosition
  isRounded?: boolean
  isStretched?: boolean
  tooltipLabel?: string
}

export function Button({
  className,
  children,
  variant,
  color,
  icon,
  iconPosition = "right",
  isDisabled,
  onClick,
  isRounded,
  isStretched,
  tooltipLabel,
}: ButtonProps): ReactElement {
  const tooltipId = useId()

  /* ----------------------------- Button styling ----------------------------- */
  const buttonClass = classNames(
    "Button",
    className,
    variant && `Button--${variant}`,
    color && `Button--${color}`,
    {
      "Button--disabled": isDisabled,
      "Button--stretched": isStretched,
      "Button--rounded": isRounded,
    },
  )

  function getIconColor(): string {
    return variant ? iconColorMapping[variant] : "white-base"
  }

  const iconElement = icon ? (
    <Icon
      size={14}
      className={`Button__icon--${iconPosition}`}
      icon={icon}
      color={getIconColor()}
    />
  ) : null

  /* --------------------------------- Render --------------------------------- */
  return (
    <>
      {tooltipLabel && <Tooltip id={tooltipId} content={tooltipLabel} />}
      <button
        disabled={isDisabled}
        className={buttonClass}
        {...(tooltipLabel && {
          "data-tooltip-id": tooltipId,
          "data-tooltip-content": tooltipLabel,
        })}
        onClick={onClick}
      >
        {iconPosition === "left" ? (
          <>
            {iconElement}
            {children}
          </>
        ) : (
          <>
            {children}
            {iconElement}
          </>
        )}
      </button>
    </>
  )
}

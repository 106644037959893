import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"

import { LoginPage } from "./pages/LoginPage/LoginPage"

export function AuthRouter(): ReactElement {
  return (
    <Routes>
      <Route path={routes.auth.login} element={<LoginPage />} />
    </Routes>
  )
}

import "./TabItem.scss"

import classNames from "classnames"
import { type ReactElement } from "react"
import { type LinkProps, NavLink } from "react-router-dom"

interface TabItemProps extends LinkProps {}

export function TabItem({ to, children }: TabItemProps): ReactElement {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames("TabItem", { "TabItem--active": isActive })
      }
    >
      {children}
    </NavLink>
  )
}

import "./Tag.scss"

import classNames from "classnames"
import type { ReactElement } from "react"

import { Icon } from "../Icon/Icon"

export interface TagProps {
  label: string
  color: string
  icon?: ReactElement
  className?: string
}

export function Tag({
  label,
  color,
  icon,
  className = "green",
}: TagProps): ReactElement {
  const tagClass = classNames("Tag", className, color && `Tag--${color}`)

  const iconElement = icon ? (
    <Icon size={14} icon={icon} color={`${color}-dark`} />
  ) : null

  return (
    <span className={tagClass}>
      {label}
      {iconElement}
    </span>
  )
}

import "./DocumentHeader.scss"

import type { ReactElement } from "react"
import { PostalAddress } from "shared/components/PostalAddress/PostalAddress"
import type { ReturnDocument } from "shared/services/interfaces"
import { formatDateToDisplay } from "shared/utils/formatDateToDisplay"
import { formatSiretOrSiren } from "shared/utils/formatSirenSiret"

export interface DocumentHeaderProps
  extends Partial<
    Pick<
      ReturnDocument,
      | "documentNumber"
      | "type"
      | "issueDate"
      | "dueDate"
      | "internalReference"
      | "clientCompany"
      | "userCompany"
      | "name"
    >
  > {}

export function DocumentHeader({
  documentNumber,
  type,
  issueDate,
  dueDate,
  internalReference,
  clientCompany,
  userCompany,
  name,
}: DocumentHeaderProps): ReactElement {
  return (
    <header className="DocumentHeader">
      <div className="DocumentHeader__Left">
        {userCompany && (
          <div className="DocumentHeader__UserCompany">
            {userCompany?.logo ? (
              <div className="DocumentHeader__UserCompany__Logo">
                <img src={`${userCompany.logo}?${Date.now()}`} />
              </div>
            ) : null}
            <span className="DocumentHeader__UserCompany__Row DocumentHeader__UserCompany__Row--fat">
              {userCompany.name}
            </span>
            {userCompany.postalAddress && (
              <span className="DocumentHeader__UserCompany__Row">
                <PostalAddress address={userCompany.postalAddress} />
              </span>
            )}
            <div className="DocumentHeader__UserCompany__Row">
              {userCompany.phone && <span>{userCompany.phone}</span>}
              {userCompany.email && <span>{userCompany.email}</span>}
            </div>
            <br />
            <div className="DocumentHeader__UserCompany__Row">
              {userCompany.siret && (
                <span>SIRET : {formatSiretOrSiren(userCompany.siret)}</span>
              )}
              {userCompany.rcsRegistration && (
                <span>RCS : {userCompany.rcsRegistration}</span>
              )}
              {userCompany.applyVAT && (
                <span>N° TVA : {userCompany.vatNumber}</span>
              )}
            </div>
          </div>
        )}
        {name ? <h4 className="DocumentHeader__Name">{name}</h4> : null}
      </div>
      <div className="DocumentHeader__Right">
        <div className="DocumentHeader__DocumentType">{type}</div>
        <div className="DocumentHeader__Meta">
          <div className="DocumentHeader__Meta__Info">
            <span className="DocumentHeader__Meta__Info__Label">Numéro</span>
            <span className="DocumentHeader__Meta__Info__Value">
              {documentNumber}
            </span>
          </div>
          {internalReference && (
            <div className="DocumentHeader__Meta__Info">
              <span className="DocumentHeader__Meta__Info__Label">
                Référence
              </span>
              <span className="DocumentHeader__Meta__Info__Value">
                {internalReference}
              </span>
            </div>
          )}
          {issueDate && (
            <div className="DocumentHeader__Meta__Info">
              <span className="DocumentHeader__Meta__Info__Label">
                Date d'émission
              </span>
              <span className="DocumentHeader__Meta__Info__Value">
                {formatDateToDisplay(issueDate)}
              </span>
            </div>
          )}
          {dueDate && (
            <div className="DocumentHeader__Meta__Info">
              <span className="DocumentHeader__Meta__Info__Label">
                Date d'échéance
              </span>
              <span className="DocumentHeader__Meta__Info__Value">
                {formatDateToDisplay(dueDate)}
              </span>
            </div>
          )}
        </div>

        {clientCompany && (
          <div className="DocumentHeader__Client">
            <div className="DocumentHeader__Client__Row DocumentHeader__Client__Row--fat">
              {clientCompany.name && <span>{clientCompany.name}</span>}
              {clientCompany.contact && <span>{clientCompany.contact}</span>}
            </div>

            {clientCompany.postalAddress && (
              <span className="DocumentHeader__Client__Row">
                <PostalAddress address={clientCompany.postalAddress} />
              </span>
            )}
            <div className="DocumentHeader__Client__Row">
              {clientCompany.email && <span>{clientCompany.email}</span>}
              {clientCompany.phone && <span>{clientCompany.phone}</span>}
            </div>

            <div className="DocumentHeader__Client__Row">
              {clientCompany.siret && (
                <span>SIRET : {formatSiretOrSiren(clientCompany.siret)}</span>
              )}
              {clientCompany.vatNumber && (
                <span>N° TVA : {clientCompany.vatNumber}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

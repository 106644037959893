import "./CreateDocumentPageView.scss"

import { type ReactElement, useRef } from "react"
import { Form } from "react-final-form"
import { toast } from "react-toastify"
import { CreateDocumentForm } from "sections/documents/pages/CreateDocumentPage/components/CreateDocumentForm/CreateDocumentForm"
import { DocumentPreview } from "sections/documents/pages/CreateDocumentPage/components/DocumentPreview/DocumentPreview"
import { useComponentSize } from "shared/hooks/useComponentSize"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import { useClientPrint } from "shared/services/hooks/documents/useClientPrint"
import type {
  AnonUserData,
  Article,
  Clients,
  Document,
  UserData,
} from "shared/services/interfaces"
import { DocumentModeEnum } from "shared/services/interfaces"
import { getLocalPaymentMode } from "shared/utils/getLocalPaymentMode"
import { saveLocalPaymentMode } from "shared/utils/saveLocalPaymentMode"

export type CreateDocumentFormValues = Partial<Document>

export interface CreateDocumentPageViewProps {
  user: UserData | AnonUserData
  clients: Clients
  existingData?: Document
}

export function CreateDocumentPageView({
  user,
  clients,
  existingData,
}: CreateDocumentPageViewProps): ReactElement {
  // Default Form Value
  // TODO: remove this later
  const currentDate = new Date()
  const defaultDueDate = new Date(
    currentDate.getTime() + 30 * 24 * 60 * 60 * 1000,
  )

  const { isAuthenticated } = useAuth()

  const initialValues: CreateDocumentFormValues = {
    userCompany: user && "company" in user ? user.company : undefined,
    paymentMode: getLocalPaymentMode(),
    type: DocumentModeEnum.Invoice,
    issueDate: currentDate.toISOString().split("T")[0],
    dueDate: defaultDueDate.toISOString().split("T")[0],
    amountVAT: 20,
    items: [{} as Article],
    ...existingData,
  }

  /* ----------------------------- Form Submission ---------------------------- */

  // Print Document
  const { printDocument, isLoading } = useClientPrint({
    isPublic: !isAuthenticated,
  })

  const isSubmitting = isLoading

  // Fonction handleSubmit
  const handleSubmit = (values: CreateDocumentFormValues) => {
    if (isSubmitting) return

    const submitProcess = async () => {
      if (values.paymentMode) saveLocalPaymentMode(values.paymentMode)

      await printDocument({
        documentData: values,
      })
    }

    toast
      .promise(submitProcess(), {
        pending: "Génération en cours...",
        success: "Terminé !",
      })
      .catch(error => {
        toast.error(
          `${error?.response?.data?.msgCode || "Une erreur est survenue"}`,
        )
        console.error("An error occurred:", error)
      })
  }
  /* -------------------------- Previewer and sizing -------------------------- */
  const wrapperRef = useRef(null)
  const documentRef = useRef(null)

  const { width: wrapperWidth, height: wrapperHeight } =
    useComponentSize(wrapperRef)
  const { width: documentWidth, height: documentHeight } =
    useComponentSize(documentRef)

  const scaleWidth = wrapperWidth / documentWidth
  const scaleHeight = wrapperHeight / documentHeight
  const scale = Math.min(scaleWidth, scaleHeight, 1)

  /* --------------------------- Component rendering -------------------------- */
  return (
    <Form<CreateDocumentFormValues>
      initialValues={initialValues}
      initialValuesEqual={() => true}
      keepDirtyOnReinitialize={true}
      render={({ values }) => {
        return (
          <form
            ref={wrapperRef}
            autoComplete="off"
            className="CreateDocumentPageView"
          >
            <div className="CreateDocumentPageView__Preview">
              {!values ? (
                <div>No data</div>
              ) : (
                <div
                  ref={documentRef}
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: "top center",
                  }}
                  className="CreateDocumentPageView__Preview__StickyWrapper__Doc"
                >
                  <DocumentPreview values={values} />
                </div>
              )}
            </div>
            <CreateDocumentForm
              isSubmitting={isSubmitting}
              clients={clients}
              onSubmitButtonPress={event => {
                event.preventDefault()
                handleSubmit(values)
              }}
            />
          </form>
        )
      }}
      onSubmit={handleSubmit}
    />
  )
}

import "./DocumentsHistoryTable.scss"

import type { ColumnDef } from "@tanstack/react-table"
import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import { IconDownloadLine, IconGitBranchLine } from "shared/assets/icons"
import { Button } from "shared/components/Button/Button"
import { Table } from "shared/components/Table/Table"
import { Tag } from "shared/components/Tag/Tag"
import { useClientPrintFromId } from "shared/services/hooks/documents/useClientPrintFromId"
import type { Client, ReturnDocument } from "shared/services/interfaces"
import { formatDateAndTimeToDisplay } from "shared/utils/formatDateAndTimeToDisplay"
import { formatPrice } from "shared/utils/formatPrice"

interface DocumentsHistoryTableProps {
  documents: ReturnDocument[]
}

export function DocumentsHistoryTable({
  documents,
}: DocumentsHistoryTableProps): ReactElement {
  const navigate = useNavigate()

  // Print Document
  const { printDocument, isLoading: isPrintDocumentLoading } =
    useClientPrintFromId()
  const handleDownloadDocument = (documentId: string) => {
    printDocument({ documentId })
  }

  // Copy document
  const handleCopyDocument = (documentId: string) => {
    navigate(routes.documents.create + `?documentId=${documentId}`)
  }

  const columns: ColumnDef<ReturnDocument>[] = [
    {
      accessorKey: "clientCompany",
      header: "Client",
      cell: ({ getValue }) => {
        const clientCompany = getValue() as Client
        return (
          <div className="DocumentHistoryTable__Cell__Client">
            {clientCompany?.name}
          </div>
        )
      },
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: ({ getValue }) => {
        const type = getValue() as string
        return <Tag color="blue" label={type} />
      },
    },
    {
      accessorKey: "createdAt",
      header: "Date",
      cell: ({ getValue }) => formatDateAndTimeToDisplay(getValue() as string),
    },
    {
      accessorKey: "totalToPay",
      header: "Total",
      cell: ({ getValue }) => formatPrice(getValue() as number),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <div className="DocumentHistoryTable__Cell__Actions">
          <Button
            variant="subtle"
            tooltipLabel="Créer un nouveau document à partir de celui-ci"
            icon={<IconGitBranchLine />}
            onClick={() => handleCopyDocument(row.original.id)}
          ></Button>
          <Button
            isDisabled={isPrintDocumentLoading}
            variant="subtle"
            icon={<IconDownloadLine />}
            tooltipLabel="Télécharger (aucun crédit ne sera débité)"
            onClick={() => handleDownloadDocument(row.original.id)}
          ></Button>
        </div>
      ),
    },
  ]

  return <Table columns={columns} data={documents} />
}

import "./DocumentPreview.scss"

import { type ReactElement } from "react"
import type { ReturnDocument } from "shared/services/interfaces"

import { DocumentFooter } from "./components/DocumentFooter/DocumentFooter"
import { DocumentHeader } from "./components/DocumentHeader/DocumentHeader"
import { ItemsTable } from "./components/ItemsTable/ItemsTable"

export interface DocumentPreviewProps {
  values: Partial<ReturnDocument>
  // hasLogo?: boolean
}

export function DocumentPreview({
  values,
}: // hasLogo = true,
DocumentPreviewProps): ReactElement {
  return (
    <div id="Document" className="DocumentPreview">
      {/*We need this div ID (id="Document"), API will check if rendered exist before print*/}

      <DocumentHeader
        documentNumber={values.documentNumber}
        type={values.type}
        issueDate={values.issueDate}
        dueDate={values.dueDate}
        internalReference={values.internalReference}
        clientCompany={values.clientCompany}
        userCompany={values.userCompany}
        name={values.name}
      />

      <ItemsTable
        items={values.items}
        hasVAT={values.hasVAT}
        amountVAT={values.amountVAT}
        totalItems={values.totalItems}
        totalDiscount={values.totalDiscount}
        totalAfterDiscount={values.totalAfterDiscount}
        totalTax={values.totalTax}
        totalPaid={values.totalPaid}
        totalToPay={values.totalToPay}
      />

      <DocumentFooter
        latePenalties={values.latePenalties}
        paymentMode={values.paymentMode}
        note={values.note}
        userCompany={values.userCompany}
        // hasLogo={hasLogo}
      />
    </div>
  )
}

import type { ReactNode } from "react"
import { toast } from "react-toastify"
import type { ErrorBatch, RetryFunction } from "shared/hooks/useBatchAppError"

import { TopLoadingBar } from "../TopLoadingBar/TopLoadingBar"

export type ErrorBatchData<T extends unknown[] = unknown[]> = {
  [P in keyof T]: NonNullable<T[P]>
}

export interface BlockWithRequestsBatchPropsWhenData<TData extends unknown[]> {
  isLoading: boolean
  requestsResultsBatch: ErrorBatch<TData>
  renderWhenLoading?: () => ReactNode
  renderWhenData: (...data: ErrorBatchData<TData>) => ReactNode
  options?: Record<string, unknown>
  renderWhenError?: (onRetry: RetryFunction) => ReactNode
}

export function BlockWithRequestsBatch<TData extends unknown[]>({
  isLoading,
  requestsResultsBatch,
  renderWhenData,
  renderWhenLoading = () => <TopLoadingBar />,
  renderWhenError,
}: BlockWithRequestsBatchPropsWhenData<TData>): ReactNode {
  const hasData = requestsResultsBatch.data.every((data: unknown) => !!data)
  const retryFunction = async (): Promise<void> => {
    await Promise.all(
      requestsResultsBatch.retryFunctions.map((retry: RetryFunction) =>
        retry(),
      ),
    )
  }
  const shouldDisplayBlockError = requestsResultsBatch.hasError

  if (isLoading) {
    return renderWhenLoading()
  }

  if (shouldDisplayBlockError) {
    if (renderWhenError) {
      return renderWhenError(retryFunction)
    }
    // TODO implement default ErrorBlock component
    return "An error occurred"
  }

  if (!hasData && !requestsResultsBatch.hasError) {
    toast.error("Erreur de récupération des données")
    console.error("BlockWithRequestsBatch: missing data, isLoading is false")
  }

  if (renderWhenData) {
    return renderWhenData(
      ...(requestsResultsBatch.data as ErrorBatchData<TData>),
    )
  }

  throw new Error('BlockWithRequestsBatch: Missing prop "renderWhenData"')
}

import type {
  CombinedCompanies,
  CombinedCompany,
} from "shared/services/interfaces"

const deduplicateCompanies = (
  companies: CombinedCompanies,
): CombinedCompanies => {
  return Array.from(new Set(companies.map(e => e.siret)))
    .map(siret => companies.find(e => e.siret === siret))
    .filter((e): e is CombinedCompany => e !== undefined)
}

export { deduplicateCompanies }

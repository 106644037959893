import "./FormSectionRow.scss"

import { type ReactElement, type ReactNode } from "react"

export interface FormSectionRowProps {
  children: ReactNode
}

export function FormSectionRow({
  children,
}: FormSectionRowProps): ReactElement {
  return <div className="FormSectionRow">{children}</div>
}

import { AppRouter } from "app/router/AppRouter"
import { type ReactNode } from "react"
import { BrowserRouter } from "react-router-dom"
import { SnackBarContainer } from "shared/components/SnackBarContainer/SnackBarContainer"
import { ApplicationLayoutContextProvider } from "shared/contexts/ApplicationLayout/ApplicationLayoutContext"
import { ProvideAuth } from "shared/services/hooks/auth/useAuth"
import { SWRConfig } from "swr"

function App(): ReactNode {
  return (
    <ProvideAuth>
      <SWRConfig value={{}}>
        <BrowserRouter>
          <ApplicationLayoutContextProvider>
            <AppRouter />
            <SnackBarContainer />
          </ApplicationLayoutContextProvider>
        </BrowserRouter>
      </SWRConfig>
    </ProvideAuth>
  )
}

export default App

import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

export function HomeRouter(): ReactElement {
  return (
    <Routes>
      <Route
        path={routes.home.index}
        element={<Navigate to={routes.documents.create} />}
      />
    </Routes>
  )
}

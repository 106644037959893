import { routes } from "app/router/routes"
import { type ReactNode, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { LoadingPage } from "shared/components/LoadingPage/LoadingPage"
import { PageTitle } from "shared/components/PageTitle/PageTitle"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import { useGetPublicUserData } from "shared/services/hooks/user/useGetPublicUserData"

import { CreateDocumentPageView } from "../../../documents/pages/CreateDocumentPage/CreateDocumentPageView"

export function CreateDocumentPagePublic(): ReactNode {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.documents.create, { replace: true })
    }
  }, [isAuthenticated, navigate])

  const {
    data: userData,
    isLoading,
    hasError,
    refetch,
  } = useGetPublicUserData()

  const requestsResultsBatch = useBatchAppError({
    data: userData,
    hasError,
    retry: refetch,
  })

  return (
    <>
      <PageTitle title="Nouveau document" />
      <BlockWithRequestsBatch
        renderWhenLoading={() => <LoadingPage />}
        isLoading={isLoading}
        requestsResultsBatch={requestsResultsBatch}
        renderWhenData={user => (
          <CreateDocumentPageView user={user} clients={[]} />
        )}
      />
    </>
  )
}

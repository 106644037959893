import { RequireAuth } from "app/router/RequireAuth"
import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"

import { PlansPage } from "./pages/PlansPage/PlansPage"

export function PlanRouter(): ReactElement {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route path={routes.plans.index} element={<PlansPage />} />
      </Route>
    </Routes>
  )
}

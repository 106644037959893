import "./ClientBlock.scss"

import type { ReactElement } from "react"
import type { Client } from "shared/services/interfaces"

export interface ClientBlockProps
  extends Pick<
    Client,
    "name" | "siret" | "postalAddress" | "logo" | "vatNumber"
  > {}

export function ClientBlock({
  name,
  siret,
  logo,
  postalAddress,
  vatNumber,
}: ClientBlockProps): ReactElement {
  return (
    <div className="ClientBlock">
      <div className="ClientBlock__CompanyLogo">
        {logo ? (
          <img
            className="ClientBlock__CompanyLogo__img"
            src={logo}
            alt={name}
          />
        ) : (
          <div className="ClientBlock__CompanyLogo__letter">
            {name.charAt(0)}
          </div>
        )}
      </div>
      <div className="ClientBlock__name">{name}</div>
      <div className="ClientBlock__postalAddress">{postalAddress}</div>
      <div className="ClientBlock__meta">Siret : {siret}</div>
      {vatNumber && <div className="ClientBlock__meta">TVA : {vatNumber}</div>}
    </div>
  )
}

import "./Layout.scss"

import * as Sentry from "@sentry/react"
import { sentryConfig } from "app/config/sentry"
import classNames from "classnames"
import { TermsModal } from "layout/_internals/TermsModal/TermsModal"
import type { ReactElement, ReactNode } from "react"
import Modal from "react-modal"
import { useLocation } from "react-router-dom"
import { appMountingPointId, rightPanelMountingPointId } from "shared/constants"
import { useApplicationLayoutContext } from "shared/contexts/ApplicationLayout/useApplicationLayoutContext"
import { useAuth } from "shared/services/hooks/auth/useAuth"

import { Footer } from "../Footer/Footer"
import { Navigation } from "../Navigation/Navigation"

interface LayoutProps {
  children: ReactNode
}

Modal.setAppElement(`#${appMountingPointId}`)

export function Layout({ children }: LayoutProps): ReactElement {
  const { isAuthenticated } = useAuth()
  const { components } = useApplicationLayoutContext()

  isAuthenticated && Sentry.init(sentryConfig)

  const layoutClasseNames = classNames("Layout", {
    "Layout--with-right-pannel": components.RIGHT_PANEL,
  })

  const location = useLocation()
  const isLoginPage = location.pathname === "/login"

  return isLoginPage ? (
    <main className={layoutClasseNames}>{children}</main>
  ) : (
    <main className={layoutClasseNames}>
      <Navigation />
      {isAuthenticated ? <TermsModal /> : null}

      <div className="Layout__content">
        <section className="Layout__section">{children}</section>
        <Footer />
      </div>

      {components.RIGHT_PANEL ? (
        <div className="Layout__right-panel" id={rightPanelMountingPointId} />
      ) : null}
    </main>
  )
}

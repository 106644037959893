import "./Navigation.scss"

import type { ReactNode } from "react"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import { useGetPublicUserData } from "shared/services/hooks/user/useGetPublicUserData"
import { useGetUserData } from "shared/services/hooks/user/useGetUserData"

import { NavigationView } from "./NavigationView"

export function Navigation(): ReactNode {
  const { isAuthenticated, isPending } = useAuth()

  const {
    data: userData,
    isLoading: isUserLoading,
    hasError: hasUserError,
    refetch: retryUser,
  } = useGetUserData({ shouldSkip: !isAuthenticated || isPending })

  const {
    data: publicUserData,
    isLoading: isPublicUserLoading,
    hasError: hasPublicUserError,
    refetch: retryPublicUser,
  } = useGetPublicUserData({ shouldSkip: isAuthenticated || isPending })

  const requestsResultsBatch = useBatchAppError(
    isAuthenticated
      ? {
          data: userData,
          hasError: hasUserError,
          retry: retryUser,
        }
      : {
          data: publicUserData,
          hasError: hasPublicUserError,
          retry: retryPublicUser,
        },
  )

  const isLoading =
    isPending || (isAuthenticated ? isUserLoading : isPublicUserLoading)

  return (
    <BlockWithRequestsBatch
      isLoading={isLoading}
      requestsResultsBatch={requestsResultsBatch}
      renderWhenData={user => (
        <NavigationView user={user} isAuthenticated={isAuthenticated} />
      )}
    />
  )
}

import type { ReactElement } from "react"
import { Field } from "react-final-form"
import { Select } from "shared/components/Select/Select"
import { DocumentModeEnum } from "shared/services/interfaces"

interface DocumentTypeFieldProps {
  name: string
  value?: string
}

const documentOptions: string[] = Object.values(DocumentModeEnum)

export function DocumentTypeField({
  name,
  value,
}: DocumentTypeFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Select
          label="Type de document"
          value={props.input.value || value}
          options={documentOptions}
          name={props.input.name}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

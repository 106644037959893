import { Fragment, type ReactElement } from "react"

export interface PostalAddressProps {
  address: string
}

export function PostalAddress({ address }: PostalAddressProps): ReactElement {
  if (!address) {
    return <></>
  }

  const lines = address.split(", ")

  return (
    <>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </>
  )
}

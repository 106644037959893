import { type ReactElement } from "react"
import { useParams } from "react-router-dom"
import { DocumentPreview } from "sections/documents/pages/CreateDocumentPage/components/DocumentPreview/DocumentPreview"
import { useGetDocument } from "shared/services/hooks/documents/useGetDocument"
// import { useGetUserData } from "shared/services/hooks/user/useGetUserData"

export function DocumentPage(): ReactElement {
  // Get document data
  const params = useParams()
  const { data, isLoading, hasError } = useGetDocument({ params })

  // Get user data
  // const { data: userData } = useGetUserData()
  // const hasLogo = userData?.plan.tier === "free"

  // TODO: generic loading/error component
  if (isLoading) return <div>Loading...</div>
  if (hasError) return <div>Something went wrong</div>
  if (!data) return <div>No data</div>

  return (
    <DocumentPreview
      values={data}
      // hasLogo={hasLogo}
    />
  )
}

import "./ProfilePage.scss"

import { type ReactElement } from "react"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { LoadingPage } from "shared/components/LoadingPage/LoadingPage"
import { PageTitle } from "shared/components/PageTitle/PageTitle"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useGetPlans } from "shared/services/hooks/pay/useGetPlans"
import { useGetUserData } from "shared/services/hooks/user/useGetUserData"
import type { UserData } from "shared/services/interfaces"

import { ProfilePageView } from "./ProfilePageView"

export type UpdateUserDataFormValue = Partial<UserData>

export function ProfilePage(): ReactElement {
  const {
    data: userData,
    isLoading: isUserLoading,
    hasError: hasUserError,
    refetch: retryUser,
  } = useGetUserData()

  const {
    data: plansData,
    isLoading: arePlansLoading,
    hasError: hasPlansError,
    refetch: retryPlans,
  } = useGetPlans()

  const requestsResultsBatch = useBatchAppError(
    {
      hasError: hasUserError,
      data: userData,
      retry: retryUser,
    },
    {
      data: plansData,
      hasError: hasPlansError,
      retry: retryPlans,
    },
  )

  const isLoading = isUserLoading || arePlansLoading

  return (
    <>
      <PageTitle title="Mon compte" />
      <BlockWithRequestsBatch
        renderWhenLoading={() => <LoadingPage />}
        isLoading={isLoading}
        requestsResultsBatch={requestsResultsBatch}
        renderWhenData={(user, plans) => (
          <ProfilePageView user={user} plans={plans} />
        )}
      />
    </>
  )
}

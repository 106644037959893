import "./ProfilePage.scss"

import { type ReactElement } from "react"
import { Form } from "react-final-form"
import { toast } from "react-toastify"
import { PlanItem } from "shared/components/PlanItem/PlanItem"
import { useCreatePortalSession } from "shared/services/hooks/pay/useCreatePortalSession"
import { useUpdateUserData } from "shared/services/hooks/user/useUpdateUserData"
import type {
  FrequencyPrices,
  Plans,
  UserData,
} from "shared/services/interfaces"

import { DangerZone } from "./Components/DangerZone/DangerZone"
import { InformationsForm } from "./Components/InformationsForm/InformationsForm"

export type UpdateUserDataFormValue = Partial<UserData>

interface ProfilePageViewProps {
  user: UserData
  plans: Plans
}

export function ProfilePageView({
  user,
  plans,
}: ProfilePageViewProps): ReactElement {
  // TODO: remove this later
  const initialValues: UpdateUserDataFormValue = {
    company: user?.company,
  }

  //Update user data
  const { mutate: updateUserData, isLoading: isUpdatingUserData } =
    useUpdateUserData()

  // Submit data
  const handleSubmit = (values: UpdateUserDataFormValue) => {
    updateUserData(values, {
      onError: response => {
        console.error(response)
        toast.error("Une erreur est survenue")
      },
      onSuccess: () => {
        toast.success("Vos compte a été mise à jour")
      },
    })
  }

  // Create portal session
  const { mutate: createPortalSession, isLoading: isCreatingPortalSession } =
    useCreatePortalSession()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const userPlan = plans.find(plan => plan.tier === user.plan.tier)
  const subscriptionFrequency = user.plan.subscriptionFrequency
  const selectedPrice =
    userPlan?.prices[subscriptionFrequency as keyof FrequencyPrices]

  return (
    <div className="ProfilePage">
      <div className="ProfilePage__Form">
        <Form<UpdateUserDataFormValue>
          initialValues={initialValues}
          render={({ handleSubmit }) => {
            return (
              <form autoComplete="off">
                <InformationsForm
                  className="ProfilePage__Form__InformationsForm"
                  isLoading={isUpdatingUserData}
                  submitForm={handleSubmit}
                  companyLogo={user.company?.logo}
                />
              </form>
            )
          }}
          onSubmit={handleSubmit}
        />

        <DangerZone />
      </div>
      <div className="ProfilePage__Plan">
        {userPlan && selectedPrice && (
          <PlanItem
            isCurrent={true}
            name={userPlan.name}
            tier={userPlan.tier}
            tagline={userPlan.tagline}
            isPopular={userPlan.isPopular}
            description={userPlan.description}
            amount={selectedPrice.amount}
            freq={selectedPrice.freq}
            credits={selectedPrice.credits}
            benefits={userPlan.benefits}
            expiration={user.plan.subscriptionCancelAt}
            isDisabled={isCreatingPortalSession}
            onManageButtonClick={() => createPortalSession()}
          />
        )}
      </div>
    </div>
  )
}

import "./Dropzone.scss"

import classNames from "classnames"
import { type ReactElement, useCallback } from "react"
import type { DropzoneProps } from "react-dropzone"
import { useDropzone } from "react-dropzone"

interface DropzonePropsField extends DropzoneProps {
  onFileChange: (file: File) => void
  previewSrc?: string
  fileType: string
  isUploading?: boolean
}

export function Dropzone({
  onFileChange,
  previewSrc,
  fileType,
  isUploading,
  ...propsRest
}: DropzonePropsField): ReactElement {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileChange(acceptedFiles[0])
    },
    [onFileChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      [fileType]: [],
    },
    multiple: false,
    onDrop,
    ...propsRest,
  })

  let message
  if (isUploading) {
    message = "En cours d'envoi..."
  } else if (isDragActive) {
    message = "Yes, juste ici ! 👌"
  } else {
    message = "Déposer un fichier ou cliquer ici pour l'ajouter."
  }

  return (
    <div className="Dropzone" {...getRootProps()}>
      {previewSrc ? (
        <div className="Dropzone__Preview">
          <img
            className="LogoPreviewer__Img"
            src={`${previewSrc}?${Date.now()}`}
          />
        </div>
      ) : (
        <div
          className={classNames("Dropzone__Zone", {
            "Dropzone__Zone--active": isDragActive,
            "Dropzone__Zone--loading": isUploading,
          })}
        >
          <input {...getInputProps()} />
          <p>{message}</p>
        </div>
      )}
    </div>
  )
}

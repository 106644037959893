import "./CustomModal.scss"

import type { ReactNode } from "react"
import Modal from "react-modal"

export interface ModalProps {
  title?: string
  children?: ReactNode
  primaryAction?: ReactNode
  secondaryAction?: ReactNode
}

export function CustomModal({
  title,
  children,
  primaryAction,
  secondaryAction,
}: ModalProps): ReactNode {
  return (
    <Modal
      className="Modal"
      overlayClassName="Overlay"
      isOpen={true}
      contentLabel="Example Modal"
    >
      <div className="Modal__Content">
        <h2>{title}</h2>
        {children}
      </div>
      <div className="Modal__Buttons">
        {primaryAction}
        {secondaryAction}
      </div>
    </Modal>
  )
}

import type {
  Company,
  CompanyPappersResponse,
} from "shared/services/interfaces"

function createFrenchVatNumber(siren: string): Company["vatNumber"] {
  const VATkey = (12 + 3 * (parseInt(siren, 10) % 97)) % 97
  return `FR${VATkey}${siren}`
}

function formatPappersCompany(company: CompanyPappersResponse): Company {
  const siren = company.siege.siret
    ? company.siege.siret.substring(0, 9)
    : company.siren
  const vatNumber = createFrenchVatNumber(siren)

  const isEntrepriseIndividuelle = [
    "Entrepreneur individuel",
    "Entreprise individuelle",
  ].includes(company.forme_juridique)

  const postalAndCity = [company.siege.code_postal, company.siege.ville]
    .filter(Boolean)
    .join(" ")

  const uniqueAddressLines = Array.from(
    new Set([
      company.siege.adresse_ligne_1,
      company.siege.adresse_ligne_2,
      company.siege.complement_adresse,
      postalAndCity,
      company.siege.pays,
    ]),
  )
  const companyName = `${company.nom_entreprise}${
    isEntrepriseIndividuelle ? " (EI)" : ""
  }`

  return {
    siret: company.siege.siret || company.siren,
    name: companyName,
    postalAddress: uniqueAddressLines.filter(Boolean).join("\n"),
    vatNumber,
  }
}

export { formatPappersCompany }

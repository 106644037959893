import axios from "axios"
import type {
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"
import useSWR from "swr"

import { useAuth } from "../auth/useAuth"

export function useGet<T>(
  url: string,
  {
    shouldSkip,
    shouldThrowOnError = true,
    contentType = "application/json",
    isPublic = false,
  }: UseGetProperties & { isPublic?: boolean },
): UseGetReturnType<T> {
  const { authCurrentUser, isAuthenticated } = useAuth()

  const fetcher = async (url: string) => {
    if (shouldSkip) return null

    const headers: Record<string, string> = {
      "Content-Type": contentType,
    }

    if (!isPublic) {
      const { idToken } = await authCurrentUser()
      if (!isAuthenticated || !idToken) return null
      headers["Authorization"] = `Bearer ${idToken}`
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${url}`,
        { headers },
      )

      if (!shouldThrowOnError && response.status !== 200) {
        throw new Error(response.statusText)
      }

      return response.data
    } catch (error) {
      if (shouldThrowOnError) {
        throw error
      }
      return null
    }
  }

  const {
    data,
    error,
    isLoading: isFetching,
    mutate,
  } = useSWR(shouldSkip ? null : url, fetcher)

  const hasError = !!error
  const isLoading = isFetching && !data

  return {
    data,
    isLoading,
    hasError,
    refetch: () => mutate(),
  }
}

import "./PlansList.scss"

import type { ReactElement } from "react"
import type { PlanItemProps } from "shared/components/PlanItem/PlanItem"
import { PlanItem } from "shared/components/PlanItem/PlanItem"

import type { PlansPageViewProps } from "../../PlansPage/PlansPageView"

interface PlansListProps extends PlansPageViewProps {
  billingMode: string
  isDisabled?: PlanItemProps["isDisabled"]
  onManageButtonClick: (selectedPriceId?: string) => Promise<void>
}

export function PlansList({
  plans,
  user,
  billingMode,
  isDisabled,
  onManageButtonClick,
}: PlansListProps): ReactElement {
  return (
    <div className="PlansList">
      {plans.map(plan => {
        const isFreePlan = plan.tier === "free"

        const selectedPrice =
          billingMode === "month" || isFreePlan
            ? plan.prices.month
            : plan.prices.year

        const isCurrent =
          user.plan.tier === plan.tier &&
          (user.plan.subscriptionFrequency === billingMode ||
            !user.plan.subscriptionFrequency ||
            (isFreePlan && billingMode === "year"))

        const handleManageButtonClick = () => {
          return onManageButtonClick(!isCurrent ? selectedPrice.id : undefined)
        }

        return (
          <PlanItem
            key={plan.tier}
            isCurrent={isCurrent}
            name={plan.name}
            tier={plan.tier}
            tagline={plan.tagline}
            isPopular={plan.isPopular}
            description={plan.description}
            amount={selectedPrice.amount}
            freq={selectedPrice.freq}
            credits={selectedPrice.credits}
            benefits={plan.benefits}
            expiration={user.plan.subscriptionCancelAt}
            isDisabled={isDisabled}
            onManageButtonClick={handleManageButtonClick}
          />
        )
      })}
    </div>
  )
}

import type { ReactNode } from "react"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { LoadingPage } from "shared/components/LoadingPage/LoadingPage"
import { PageTitle } from "shared/components/PageTitle/PageTitle"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useGetClients } from "shared/services/hooks/clients/useGetClients"
import { useGetUserData } from "shared/services/hooks/user/useGetUserData"

import { CreateDocumentPageView } from "./CreateDocumentPageView"
import { CreateDocumentWithDocument } from "./CreateDocumentWithDocument"

export function CreateDocumentPage(): ReactNode {
  const {
    data: clientsData,
    isLoading: isClientLoading,
    hasError: hasClientsError,
    refetch: retryClients,
  } = useGetClients()

  const {
    data: userData,
    isLoading: isUserLoading,
    hasError: hasUserError,
    refetch: retryUser,
  } = useGetUserData()

  //Update inital values with copied document data
  const queryParameters = new URLSearchParams(window.location.search)
  const copiedDocumentId = queryParameters.get("documentId") || undefined

  const requestsResultsBatch = useBatchAppError(
    {
      hasError: hasUserError,
      data: userData,
      retry: retryUser,
    },
    {
      data: clientsData,
      hasError: hasClientsError,
      retry: retryClients,
    },
  )

  const isLoading = isUserLoading || isClientLoading

  return (
    <>
      <PageTitle title="Nouveau document" />
      <BlockWithRequestsBatch
        renderWhenLoading={() => <LoadingPage />}
        isLoading={isLoading}
        requestsResultsBatch={requestsResultsBatch}
        renderWhenData={(user, clients) =>
          copiedDocumentId ? (
            <CreateDocumentWithDocument
              user={user}
              clients={clients}
              documentId={copiedDocumentId}
            />
          ) : (
            <CreateDocumentPageView user={user} clients={clients} />
          )
        }
      />
    </>
  )
}

function formatDateAndTimeToDisplay(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    dayPeriod: "short",
  }
  const date = new Date(dateString)
  return date.toLocaleDateString("fr-FR", options)
}

export { formatDateAndTimeToDisplay }

import { type ReactElement } from "react"
import { FormSection } from "shared/components/FormSection/FormSection"

import { NoteField } from "./_internals/NoteField/NoteField"

interface NoteFormProps {
  id?: string
  isExpanded?: boolean
  handleFormFocus?: () => void
}

export function NoteForm({
  id,
  isExpanded,
  handleFormFocus,
}: NoteFormProps): ReactElement {
  return (
    <FormSection
      id={id}
      isExpanded={isExpanded}
      handleFormFocus={handleFormFocus}
      title="Note"
    >
      <NoteField name="note" />
    </FormSection>
  )
}

import type {
  Plans,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"

import { useGet } from "../core/useGet"

export function useGetPlans(
  options: UseGetProperties = {},
): UseGetReturnType<Plans> {
  return useGet("plans/listPlans", options)
}

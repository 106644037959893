import "./PlansPage.scss"

import type { ReactElement } from "react"
import { useState } from "react"
import { useCreatePortalSession } from "shared/services/hooks/pay/useCreatePortalSession"
import type { BillingMode, Plans, UserData } from "shared/services/interfaces"

import { BillingModeSwitch } from "../components/BillingModeSwitch/BillingModeSwitch"
import { PlansList } from "../components/PlansList/PlansList"

export interface PlansPageViewProps {
  plans: Plans
  user: UserData
}

export function PlansPageView({
  user,
  plans,
}: PlansPageViewProps): ReactElement {
  const [billingMode, setBillingMode] = useState<BillingMode>(
    user.plan.subscriptionFrequency,
  )

  const { mutate: createPortalSession, isLoading: isCreatingPortalSession } =
    useCreatePortalSession()

  return (
    <div>
      <div className="PlansPage">
        <div className="PlansPage__Header">
          <h1>Choisissez la formule qui vous convient le mieux</h1>
        </div>
        <BillingModeSwitch
          billingMode={billingMode}
          onChange={setBillingMode}
        />
        <PlansList
          plans={plans}
          user={user}
          billingMode={billingMode}
          isDisabled={isCreatingPortalSession}
          onManageButtonClick={createPortalSession}
        />
      </div>
    </div>
  )
}

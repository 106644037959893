import { Document, Page } from "@react-pdf/renderer"
import type { ReactElement } from "react"
import type { Document as DocumentType } from "shared/services/interfaces"

import { DocumentFooter } from "./components/DocumentFooter/DocumentFooter"
import { DocumentHeader } from "./components/DocumentHeader/DocumentHeader"
import { ItemsTable } from "./components/ItemsTable/ItemsTable"
import { documentStyle } from "./PDFDocumentStyle"

export interface DocumentPreviewProps {
  values: Partial<DocumentType>
}

function PDFDocument({ values }: DocumentPreviewProps): ReactElement {
  return (
    <Document
      title={values.documentNumber}
      subject={values.name}
      author={values.userCompany?.name}
      creator={values.userCompany?.name}
      producer="Stabbl"
      pageMode="fullScreen"
      pageLayout="oneColumn"
    >
      <Page size="A4" style={documentStyle.page}>
        <DocumentHeader
          documentNumber={values.documentNumber}
          type={values.type}
          issueDate={values.issueDate}
          dueDate={values.dueDate}
          internalReference={values.internalReference}
          clientCompany={values.clientCompany}
          userCompany={values.userCompany}
          name={values.name}
        />
        <ItemsTable
          items={values.items}
          hasVAT={values.hasVAT}
          amountVAT={values.amountVAT}
          totalItems={values.totalItems}
          totalDiscount={values.totalDiscount}
          totalAfterDiscount={values.totalAfterDiscount}
          totalTax={values.totalTax}
          totalPaid={values.totalPaid}
          totalToPay={values.totalToPay}
        />

        <DocumentFooter
          latePenalties={values.latePenalties}
          paymentMode={values.paymentMode}
          note={values.note}
          userCompany={values.userCompany}
        />
      </Page>
    </Document>
  )
}

export { PDFDocument }

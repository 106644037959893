import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import type { HubCapsule } from "@aws-amplify/core"
import type {
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js"
import { amplifyConfig } from "app/config/amplify"
import { Amplify, Auth, Hub } from "aws-amplify"
import { useEffect, useState } from "react"
import type { AuthState, UseAuth, UserToken } from "shared/services/interfaces"

Amplify.configure(amplifyConfig)

export const useProvideAuth = (): UseAuth => {
  const [authState, setAuthState] = useState<AuthState>({
    isPending: true,
    isAuthenticated: false,
    error: null,
  })

  useEffect(() => {
    authCurrentUser()
    const listener = (data: HubCapsule) => {
      switch (data.payload.event) {
        case "signIn":
          authCurrentUser()
          setAuthState({
            isPending: false,
            isAuthenticated: true,
            error: null,
          })
          break
        case "signOut":
          setAuthState({
            isPending: false,
            isAuthenticated: false,
            error: null,
          })
          break
        default:
          break
      }
    }

    const unsubscribe = Hub.listen("auth", listener)
    return () => unsubscribe()
  }, [])

  const authCurrentUser = async (): Promise<UserToken> => {
    try {
      const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
      const session: CognitoUserSession | undefined =
        cognitoUser?.getSignInUserSession() as CognitoUserSession | undefined
      const idToken = session?.getIdToken().getJwtToken()
      const accessToken = session?.getAccessToken().getJwtToken()

      const cognitoUserAttributes = await Auth.userAttributes(cognitoUser)

      const attributes: Record<string, string> = {}
      cognitoUserAttributes.forEach(attribute => {
        if (attribute.Name && attribute.Value) {
          attributes[attribute.Name] = attribute.Value
        }
      })

      setAuthState({
        userAttributes: attributes,
        isPending: false,
        isAuthenticated: true,
        error: null,
      })
      return {
        idToken,
        accessToken,
      }
    } catch (error) {
      setAuthState({
        isPending: false,
        isAuthenticated: false,
        error: "Erreur d'authentification",
      })
      return {
        idToken: null,
        accessToken: null,
      }
    }
  }

  const signIn = () => {
    setAuthState({
      ...authState,
      isPending: true,
    })
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
  }

  const signOut = async () => {
    try {
      await Auth.signOut()
      setAuthState({
        isPending: false,
        isAuthenticated: false,
        error: null,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    ...authState,
    authCurrentUser,
    signIn,
    signOut,
  }
}

import type { Companies } from "shared/services/interfaces"

const filterCompaniesByName = (
  companies: Companies,
  searchTerm: string,
): Companies => {
  return companies.filter(company =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )
}

export { filterCompaniesByName }

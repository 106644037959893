import "./LabelledCheckbox.scss"

import classNames from "classnames"
import type { ReactElement, ReactNode } from "react"

import type { CheckboxProps } from "../Checkbox/Checkbox"
import { Checkbox } from "../Checkbox/Checkbox"

export interface LabelledCheckboxProps extends CheckboxProps {
  children?: ReactNode
}

export function LabelledCheckbox({
  children,
  className,
  isDisabled,
  ...checkBoxProps
}: LabelledCheckboxProps): ReactElement {
  const labelClass = classNames("LabelledCheckbox__label", {
    "LabelledCheckbox__label--disabled": isDisabled,
  })

  return (
    <label className={classNames("LabelledCheckbox", className)}>
      <Checkbox isDisabled={isDisabled} {...checkBoxProps} />
      <span className={labelClass}>{children}</span>
    </label>
  )
}

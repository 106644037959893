import "./CreateDocumentForm.scss"

import type { MouseEvent, ReactElement } from "react"
import { useEffect, useState } from "react"
import { useForm, useFormState } from "react-final-form"
import { IconDownloadLine } from "shared/assets/icons"
import { Button } from "shared/components/Button/Button"
import { RightPanel } from "shared/components/RightPanel/RightPanel"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import { useGetPublicUserData } from "shared/services/hooks/user/useGetPublicUserData"
import { useGetUserData } from "shared/services/hooks/user/useGetUserData"
import type { Clients } from "shared/services/interfaces"

import { ClientForm } from "./components/ClientForm/ClientForm"
import { DocumentTypeForm } from "./components/DocumentTypeForm/DocumentTypeForm"
import { InformationsForm } from "./components/InformationsForm/InformationsForm"
import { ItemsForm } from "./components/ItemsForm/ItemsForm"
import { NoteForm } from "./components/NoteForm/NoteForm"
import { PaymentForm } from "./components/PaymentForm/PaymentForm"

export interface CreateDocumentFormProps {
  clients: Clients
  isSubmitting: boolean
  onSubmitButtonPress: (event: MouseEvent<HTMLButtonElement>) => void
}

export function CreateDocumentForm({
  clients,
  isSubmitting,
  onSubmitButtonPress,
}: CreateDocumentFormProps): ReactElement {
  const { isAuthenticated } = useAuth()
  const { values } = useFormState()
  const { batch, change } = useForm()
  const { data: userData } = useGetUserData()
  const { data: publicUserData } = useGetPublicUserData({
    shouldSkip: isAuthenticated,
  })
  const credits = isAuthenticated
    ? userData?.plan?.credits.toString()
    : publicUserData?.credits.toString()
  const hasCredits =
    // FIXME : immonde ci-dessous
    credits && (credits === "unlimited" || parseInt(credits) > 0)

  // Compute Totals
  useEffect(() => {
    let totalItems = 0
    if (!values.items || values.items.length === 0) return

    //0. Appliquer la TVA sur le document si le user l'a sur sa company
    const hasVAT = values.userCompany?.applyVAT

    // 1. Calcul du total des articles (sous-total)
    for (const item of values.items) {
      if (item.unitPrice && item.quantity) {
        totalItems += item.unitPrice * item.quantity
      }
    }

    // 2. Montant total de la remise
    const totalDiscount = values.discount || 0

    // 3. Total après remise (total HT)
    const totalAfterDiscount = totalItems - totalDiscount

    // 4. Montant total de la taxe
    const totalTax = hasVAT ? totalAfterDiscount * (values.amountVAT / 100) : 0

    // 5. Montant déjà payé
    const totalPaid = values.paid || 0

    // 6. Total à payer (total TTC)
    const totalToPay = totalAfterDiscount + totalTax - totalPaid

    batch(() => {
      change("hasVAT", hasVAT)
      change("totalItems", totalItems)
      change("totalDiscount", totalDiscount)
      change("totalAfterDiscount", totalAfterDiscount)
      change("totalTax", totalTax)
      change("totalPaid", totalPaid)
      change("totalToPay", totalToPay)
    })
  }, [values.items, values.userCompany?.applyVAT, values.amountVAT])

  const [activeForm, setActiveForm] = useState("InformationsForm")

  return (
    <RightPanel>
      <div className="CreateDocumentForm">
        <InformationsForm
          id="InformationsForm"
          handleFormFocus={() => setActiveForm("InformationsForm")}
          isExpanded={activeForm === "InformationsForm"}
        />
        <DocumentTypeForm
          id="DocumentTypeForm"
          handleFormFocus={() => setActiveForm("DocumentTypeForm")}
          isExpanded={activeForm === "DocumentTypeForm"}
        />
        <ClientForm
          id="ClientForm"
          clients={clients}
          handleFormFocus={() => setActiveForm("ClientForm")}
          isExpanded={activeForm === "ClientForm"}
        />
        <ItemsForm
          id="ItemsForm"
          handleFormFocus={() => setActiveForm("ItemsForm")}
          isExpanded={activeForm === "ItemsForm"}
        />
        <PaymentForm
          id="PaymentForm"
          handleFormFocus={() => setActiveForm("PaymentForm")}
          isExpanded={activeForm === "PaymentForm"}
        />
        <NoteForm
          id="NoteForm"
          handleFormFocus={() => setActiveForm("NoteForm")}
          isExpanded={activeForm === "NoteForm"}
        />

        <div className="CreateDocumentForm__Actions">
          <Button
            isStretched
            iconPosition="left"
            icon={<IconDownloadLine />}
            isDisabled={isSubmitting || !hasCredits}
            onClick={onSubmitButtonPress}
          >
            Télécharger
          </Button>
        </div>
      </div>
    </RightPanel>
  )
}

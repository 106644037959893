import type {
  PortalSession,
  UsePostProperties,
  UsePostReturnType,
} from "shared/services/interfaces"

import { usePost } from "../core/usePost"

export interface UseCreatePortalSessionVariables {
  selectedPriceId?: string
  redirect: string
}

type UseCreatePortalSessionProperties =
  UsePostProperties<UseCreatePortalSessionVariables>

interface UseCreatePortalSessionReturnType
  extends Omit<
    UsePostReturnType<PortalSession, UseCreatePortalSessionVariables>,
    "mutate"
  > {
  mutate: (
    selectedPriceId?: UseCreatePortalSessionVariables["selectedPriceId"],
  ) => Promise<void>
}

export function useCreatePortalSession(
  options?: UseCreatePortalSessionProperties,
): UseCreatePortalSessionReturnType {
  const { mutate: createPortalSession, ...rest } = usePost<
    PortalSession,
    UseCreatePortalSessionVariables
  >(`pay/createPortalSession`, options)

  const handleCreatePortalSession: UseCreatePortalSessionReturnType["mutate"] =
    async selectedPriceId => {
      await createPortalSession(
        {
          selectedPriceId,
          redirect: window.location.href,
        },
        {
          onSuccess: data => {
            window.location.href = data.url
          },
          onError: error => {
            // FIXME There is probably a cleaner way to do this.
            console.error(error)
          },
        },
      )
    }

  return { mutate: handleCreatePortalSession, ...rest }
}

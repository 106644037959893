import { type ReactElement } from "react"
import { Button } from "shared/components/Button/Button"
import { AddressField } from "shared/components/FormInputs/AddressField"
import { EmailField } from "shared/components/FormInputs/EmailField"
import { LogoField } from "shared/components/FormInputs/LogoField/LogoField"
import { PhoneField } from "shared/components/FormInputs/PhoneField"
import { SiretField } from "shared/components/FormInputs/SiretField"
import { TogglableInput } from "shared/components/FormInputs/TogglableInput/TogglableInput"
import { UserCompanyField } from "shared/components/FormInputs/UserCompanyField/UserCompanyField"
import { FormSection } from "shared/components/FormSection/FormSection"

interface InformationFormProps {
  submitForm: () => void
  isLoading: boolean
  companyLogo?: string
  className?: string
}

export function InformationsForm({
  submitForm,
  isLoading,
  companyLogo,
  className,
}: InformationFormProps): ReactElement {
  return (
    <FormSection isExpanded title="Mes informations" className={className}>
      <UserCompanyField name="company.name" fieldName="company" />
      <AddressField name="company.postalAddress" />
      <SiretField name="company.siret" />
      <EmailField name="company.email" />
      <PhoneField name="company.phone" />
      <TogglableInput
        name="company.vatNumber"
        checkboxLabel="J'applique la TVA"
        checkboxName="company.applyVAT"
        inputLabel="Numero de TVA"
      />
      <TogglableInput
        name="company.rcsRegistration"
        checkboxLabel="Je suis immatriculé au RCS"
        checkboxName="company.hasRCS"
        inputLabel="Numero RCS"
      />
      <LogoField name="company.logo" logo={companyLogo} />
      <Button isDisabled={isLoading} onClick={submitForm}>
        Mettre à jour
      </Button>
    </FormSection>
  )
}

import { type ReactElement } from "react"
import { useFormState } from "react-final-form"
import type { CreateDocumentFormValues } from "sections/documents/pages/CreateDocumentPage/CreateDocumentPageView"
import { AddressField } from "shared/components/FormInputs/AddressField"
import { ContactField } from "shared/components/FormInputs/ContactField"
import { EmailField } from "shared/components/FormInputs/EmailField"
import { SiretField } from "shared/components/FormInputs/SiretField"
import { VatField } from "shared/components/FormInputs/VatField"
import { FormSection } from "shared/components/FormSection/FormSection"
import type { Client, Clients } from "shared/services/interfaces"

import { CompanyField } from "./components/CompanyField"

export interface ClientFormProps {
  id?: string
  isExpanded?: boolean
  handleFormFocus?: () => void
  clients: Clients
}

export function ClientForm({
  id,
  isExpanded,
  handleFormFocus,
  clients,
}: ClientFormProps): ReactElement {
  const { values } = useFormState<CreateDocumentFormValues>()
  const { clientCompany } = values

  const requiredFields: (keyof Client)[] = ["name", "siret", "postalAddress"]
  const completedFieldsCount = requiredFields.reduce((count, fieldName) => {
    if (
      clientCompany &&
      fieldName in clientCompany &&
      clientCompany[fieldName]
    ) {
      return count + 1
    }
    return count
  }, 0)

  return (
    <FormSection
      id={id}
      title="Client"
      isExpanded={isExpanded}
      handleFormFocus={handleFormFocus}
      progress={{
        totalFieldsCount: requiredFields.length,
        completedFieldsCount: completedFieldsCount,
      }}
    >
      <CompanyField clients={clients} />
      <SiretField name="clientCompany.siret" value={clientCompany?.siret} />
      <AddressField
        name="clientCompany.postalAddress"
        value={clientCompany?.postalAddress}
      />
      <ContactField
        name="clientCompany.contact"
        value={clientCompany?.contact}
      />
      <EmailField name="clientCompany.email" value={clientCompany?.email} />
      <VatField
        name="clientCompany.vatNumber"
        value={clientCompany?.vatNumber}
      />
    </FormSection>
  )
}

import "./CompanyFieldSuggestion.scss"

import type { ReactElement } from "react"
import { IconHistoryFill } from "shared/assets/icons"
import { Icon } from "shared/components/Icon/Icon"
import { PostalAddress } from "shared/components/PostalAddress/PostalAddress"
import type { Company } from "shared/services/interfaces"
import { formatSiretOrSiren } from "shared/utils/formatSirenSiret"

interface CompanyFieldSuggestionProps {
  company: Company
  isHistoric?: boolean
}

export function CompanyFieldSuggestion({
  company,
  isHistoric,
}: CompanyFieldSuggestionProps): ReactElement {
  return (
    <div className="CompanyField__suggestion">
      <div className="CompanySuggestion__name">
        <span>{company.name}</span>
        {isHistoric && <Icon icon={<IconHistoryFill />} color="grey-mid" />}
      </div>
      <div className="CompanySuggestion__infos">
        <div className="CompanySuggestion__infos__address">
          <PostalAddress address={company.postalAddress} />
        </div>
        <span>SIRET : {formatSiretOrSiren(company.siret)}</span>
      </div>
    </div>
  )
}

import { type ReactElement, useEffect, useState } from "react"
import { useField } from "react-final-form"
import { IconSearch } from "shared/assets/icons"
import { CompanyFieldSuggestion } from "shared/components/FormInputs/CompanyFieldSuggestions/CompanyFieldSuggestion"
import { Input } from "shared/components/Input/Input"
import { useGetCompaniesSuggestions } from "shared/services/hooks/companies/useGetCompaniesSuggestions"
import type {
  Clients,
  CombinedCompany,
  Companies,
} from "shared/services/interfaces"
import { deduplicateCompanies } from "shared/utils/deduplicateCompanies"
import { filterCompaniesByName } from "shared/utils/filterCompaniesByName"
import { flagHistoricClient } from "shared/utils/flagHistoricClient"

const getCompanyId = (company: CombinedCompany): string => company.siret

interface CompanyFieldProps {
  clients: Clients
}

export function CompanyField({ clients }: CompanyFieldProps): ReactElement {
  const clientFieldProp = useField("clientCompany")
  const nameFieldProp = useField("clientCompany.name")

  const handleSelectClient = (combined: CombinedCompany) => {
    clientFieldProp.input.onChange(combined)
  }

  const searchTerm = nameFieldProp.input.value

  const [localSuggestions, setLocalSuggestions] = useState<Companies>([])

  const {
    data: companiesSearchResults,
    isLoading: companiesSearchLoading,
    // hasError: companiesSearchError,
  } = useGetCompaniesSuggestions(searchTerm, {
    shouldSkip: !searchTerm,
  })

  const existingClients = flagHistoricClient(clients)

  useEffect(() => {
    let updatedSuggestions: CombinedCompany[] = []
    const filteredExistingClients = filterCompaniesByName(
      existingClients,
      searchTerm,
    )
    if (!searchTerm) {
      updatedSuggestions = [...existingClients]
    } else {
      updatedSuggestions = [...filteredExistingClients]
      if (!companiesSearchLoading && companiesSearchResults) {
        updatedSuggestions = [...updatedSuggestions, ...companiesSearchResults]
      }
    }
    const uniqueSuggestions = deduplicateCompanies(updatedSuggestions)
    setLocalSuggestions(uniqueSuggestions)
  }, [companiesSearchLoading, searchTerm])

  return (
    <Input<CombinedCompany>
      className="CompanyField"
      suggestions={localSuggestions}
      label="Nom de l’entreprise"
      value={nameFieldProp.input.value}
      name={nameFieldProp.input.name}
      renderSuggestion={combined => (
        <CompanyFieldSuggestion
          company={combined}
          isHistoric={combined.isHistoric}
        />
      )}
      getSuggestionId={getCompanyId}
      icon={<IconSearch />}
      onSelectSuggestion={handleSelectClient}
      onChange={nameFieldProp.input.onChange}
    />
  )
}

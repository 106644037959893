import "./Icon.scss"

import classNames from "classnames"
import { type ReactElement } from "react"

export interface IconProps {
  icon: ReactElement
  className?: string
  color?: string
  size?: number
}

export function Icon({
  icon,
  color,
  size = 16,
  className,
}: IconProps): ReactElement {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
      className={classNames("Icon", color && `Icon--${color}`, className)}
    >
      {icon}
    </div>
  )
}

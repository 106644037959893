import type { ReactElement } from "react"

interface CloudinaryImageOptions {
  fallbackWidth: number
  steps: number
}

export interface CloudinaryImageProps {
  filename: string
  extension?: string
  widthAuto?: CloudinaryImageOptions
  alt?: string
  className?: string
  onClick?: () => void
}

export function CloudinaryImage({
  filename,
  extension = "jpg",
  widthAuto,
  alt,
  className,
  onClick,
}: CloudinaryImageProps): ReactElement {
  const options = widthAuto
    ? `w_auto:${widthAuto.steps}:${widthAuto.fallbackWidth}/`
    : ""

  return (
    <img
      src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_ID_PATH}/image/upload/${options}${filename}.${extension}`}
      className={className}
      alt={filename || alt}
      onClick={onClick}
    />
  )
}

import type {
  UsePostProperties,
  UsePostReturnType,
  UserData,
} from "shared/services/interfaces"

import { usePost } from "../core/usePost"

export type UseUpdateUserDataVariables = Partial<UserData>

export type UseUpdateUserDataProperties =
  UsePostProperties<UseUpdateUserDataVariables>

export function useUpdateUserData(
  options?: UseUpdateUserDataProperties,
): UsePostReturnType<UserData, UseUpdateUserDataVariables> {
  return usePost<UserData, UseUpdateUserDataVariables>(`user`, options)
}

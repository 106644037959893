import { type ReactElement } from "react"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { LoadingPage } from "shared/components/LoadingPage/LoadingPage"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useGetDocuments } from "shared/services/hooks/documents/useGetDocuments"

import { DocumentsHistoryPageView } from "./DocumentsHistoryPageView"

export function DocumentsHistoryPage(): ReactElement {
  const {
    data: documentsData,
    isLoading: areDocumentsLoading,
    hasError: hasDocumentsError,
    refetch: retryDocuments,
  } = useGetDocuments()

  const requestsResultsBatch = useBatchAppError({
    data: documentsData,
    hasError: hasDocumentsError,
    retry: retryDocuments,
  })

  return (
    <BlockWithRequestsBatch
      renderWhenLoading={() => <LoadingPage />}
      isLoading={areDocumentsLoading}
      requestsResultsBatch={requestsResultsBatch}
      renderWhenData={documents => (
        <DocumentsHistoryPageView documents={documents} />
      )}
    />
  )
}

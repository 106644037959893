import type { ReactElement } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAuth } from "shared/services/hooks/auth/useAuth"

import { routes } from "./routes"

export function RequireAuth(): ReactElement | null {
  const { isAuthenticated, isPending } = useAuth()
  const location = useLocation()

  if (isPending) {
    return null
  }

  if (!isAuthenticated) {
    return (
      <Navigate replace to={routes.auth.login} state={{ from: location }} />
    )
  }

  return <Outlet />
}

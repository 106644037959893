import { StyleSheet } from "@react-pdf/renderer"

export const styles = StyleSheet.create({
  ItemsTable: {
    display: "flex",
    flexDirection: "column",
  },
  ItemsTableHeader: {
    display: "flex",
    flexDirection: "row",
    padding: "6pt 0pt",
    fontSize: 8,
    fontWeight: "medium",
    borderBottom: "1pt solid #000",
  },
  ItemsTableMainColumn: {
    flex: 3,
  },
  ItemsTableColumn: {
    flex: 1,
    textAlign: "right",
  },
  ItemsTableRow: {
    display: "flex",
    flexDirection: "row",
    padding: "6pt 0",
    alignItems: "center",
  },
  ItemTableDescription: {
    color: "#545454",
  },
  ItemsTableFooter: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    marginTop: 16,
    width: "55%",
  },
  ItemTableFooterTotalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1pt solid #cccccc",
    padding: "6pt 0",
  },
  ItemTableFooterTotalLastRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1pt solid #cccccc",
    padding: "6pt 0",
    fontWeight: "bold",
  },
  ItemsTableFooterNoVat: {
    textAlign: "right",
    padding: "6pt 0",
    color: "#545454",
  },
})

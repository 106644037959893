import "./LoadingPage.scss"

import type { ReactElement } from "react"

import { Spinner } from "../Spinner/Spinner"

export function LoadingPage(): ReactElement {
  return (
    <div className="LoadingPage">
      <Spinner size={64} />
    </div>
  )
}

import { Text, View } from "@react-pdf/renderer"
import type { ReactElement } from "react"
import type { ReturnDocument } from "shared/services/interfaces"
import { formatPrice } from "shared/utils/formatPrice"

import { styles } from "./ItemsTableStyle"

export interface ItemsTableProps
  extends Partial<
    Pick<
      ReturnDocument,
      | "items"
      | "hasVAT"
      | "amountVAT"
      | "totalItems"
      | "totalDiscount"
      | "totalAfterDiscount"
      | "totalTax"
      | "totalPaid"
      | "totalToPay"
    >
  > {}

export function ItemsTable({
  items,
  hasVAT,
  amountVAT,
  totalItems,
  totalDiscount,
  totalAfterDiscount,
  totalTax,
  totalPaid,
  totalToPay,
}: ItemsTableProps): ReactElement {
  return (
    <View style={styles.ItemsTable}>
      {/* Items */}
      <View>
        <View style={styles.ItemsTableHeader} fixed={true}>
          <Text style={styles.ItemsTableMainColumn}>Désignation</Text>
          <Text style={styles.ItemsTableColumn}>Qté</Text>
          <Text style={styles.ItemsTableColumn}>Unité</Text>
          <Text style={styles.ItemsTableColumn}>Prix Unit. HT</Text>
          <Text style={styles.ItemsTableColumn}>Total HT</Text>
        </View>
        <View>
          {items?.map((item, index) => (
            <View key={index} style={styles.ItemsTableRow}>
              <View style={styles.ItemsTableMainColumn}>
                <Text>{item.name}</Text>
                <Text style={styles.ItemTableDescription}>
                  {item.description}
                </Text>
              </View>
              <Text style={styles.ItemsTableColumn}>{item.quantity}</Text>
              <Text style={styles.ItemsTableColumn}>{item.unit}</Text>
              <Text style={styles.ItemsTableColumn}>
                {formatPrice(item.unitPrice)}
              </Text>
              <Text style={styles.ItemsTableColumn}>
                {formatPrice(item.unitPrice * item.quantity)}
              </Text>
            </View>
          ))}
        </View>
      </View>

      {/* Totals */}
      <View style={styles.ItemsTableFooter} wrap={false}>
        {!hasVAT && (
          <Text style={styles.ItemsTableFooterNoVat}>
            TVA Non applicable en vertu de l'art 293B du CGI
          </Text>
        )}
        <View style={styles.ItemTableFooterTotalRow}>
          <Text>Total HT</Text>
          <Text>{totalItems ? formatPrice(totalItems) : "-"}</Text>
        </View>
        {totalDiscount && totalAfterDiscount ? (
          <>
            <View style={styles.ItemTableFooterTotalRow}>
              <Text>Remise</Text>
              <Text>- {formatPrice(totalDiscount)}</Text>
            </View>

            <View style={styles.ItemTableFooterTotalRow}>
              <Text>Total après remise</Text>
              <Text>{formatPrice(totalAfterDiscount)}</Text>
            </View>
          </>
        ) : null}

        {hasVAT && totalTax ? (
          <View style={styles.ItemTableFooterTotalRow}>
            <Text>Total TVA ({amountVAT}%)</Text>
            <Text>{formatPrice(totalTax)}</Text>
          </View>
        ) : null}

        {totalPaid ? (
          <View style={styles.ItemTableFooterTotalRow}>
            <Text>Total TVA</Text>
            <Text>{formatPrice(totalPaid)}</Text>
          </View>
        ) : null}

        <View style={styles.ItemTableFooterTotalRow}>
          <Text>Total TTC</Text>
          <Text>{totalToPay ? formatPrice(totalToPay) : "-"}</Text>
        </View>

        <View style={styles.ItemTableFooterTotalLastRow}>
          <Text>Montant à payer</Text>
          <Text>
            {totalToPay ? formatPrice(totalToPay - (totalPaid || 0)) : "-"}
          </Text>
        </View>
      </View>
    </View>
  )
}

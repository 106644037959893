import type { ReactElement } from "react"
import { Helmet } from "react-helmet"

interface PageTitleProps {
  title?: string
}

export function PageTitle({ title }: PageTitleProps): ReactElement {
  const fullTitle = title ? `${title} - Stabbl` : "Stabbl"
  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  )
}

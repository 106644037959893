import { type ReactElement, useEffect, useState } from "react"
import { useField } from "react-final-form"
import { IconSearch } from "shared/assets/icons"
import { CompanyFieldSuggestion } from "shared/components/FormInputs/CompanyFieldSuggestions/CompanyFieldSuggestion"
import { Input } from "shared/components/Input/Input"
import { useGetCompaniesSuggestions } from "shared/services/hooks/companies/useGetCompaniesSuggestions"
import type { Company } from "shared/services/interfaces"

interface UserCompanyFieldProps {
  name: string
  value?: string
  fieldName: string
}

const getCompanyId = (company: Company): string => company.siret

export function UserCompanyField({
  name,
  value,
  fieldName,
}: UserCompanyFieldProps): ReactElement {
  const userCompanyFieldProp = useField(fieldName)
  const nameFieldProp = useField(name)

  const handleSelectCompany = (company: Company) => {
    userCompanyFieldProp.input.onChange(company)
  }

  const searchTerm = nameFieldProp.input.value
  const [localSuggestions, setLocalSuggestions] = useState<Company[]>([])

  const {
    data: companiesSearchResults,
    isLoading: companiesSearchLoading,
    // hasError: companiesSearchError,
  } = useGetCompaniesSuggestions(searchTerm, {
    shouldSkip: !searchTerm,
  })

  // Prevent empty suggestion array while loading
  useEffect(() => {
    if (!companiesSearchLoading && companiesSearchResults) {
      setLocalSuggestions(companiesSearchResults)
    }
  }, [companiesSearchLoading, searchTerm])

  return (
    <Input<Company>
      className="CompanyField"
      suggestions={localSuggestions}
      label="Établissement"
      value={nameFieldProp.input.value || value}
      name={nameFieldProp.input.name}
      renderSuggestion={company => <CompanyFieldSuggestion company={company} />}
      getSuggestionId={getCompanyId}
      icon={<IconSearch />}
      onSelectSuggestion={handleSelectCompany}
      onChange={nameFieldProp.input.onChange}
    />
  )
}

import "./DocumentsHistoryPageView.scss"

import { type ReactElement } from "react"
import { DocumentsHistoryTable } from "sections/documents/pages/DocumentsHistoryPage/components/DocumentsHistoryTable/DocumentsHistoryTable"
import { PageTitle } from "shared/components/PageTitle/PageTitle"
import type { ReturnDocuments } from "shared/services/interfaces"

interface DocumentsHistoryPageViewProps {
  documents: ReturnDocuments
}

export function DocumentsHistoryPageView({
  documents,
}: DocumentsHistoryPageViewProps): ReactElement {
  return (
    <>
      <PageTitle title="Historique" />
      <div className="DocumentsHistoryPageView">
        <div className="DocumentsHistoryPageView__List">
          <DocumentsHistoryTable documents={documents} />
        </div>
      </div>
    </>
  )
}

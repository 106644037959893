import type { Companies } from "shared/services/interfaces"

const flagHistoricClient = (companies: Companies): Companies => {
  return companies.map(company => ({
    ...company,
    isHistoric: true,
  }))
}

export { flagHistoricClient }

import "./DropdownList.scss"

import classNames from "classnames"
import type { ReactNode, RefObject } from "react"
import React, { useLayoutEffect, useState } from "react"
import { usePopper } from "react-popper"

import { DropdownListItem } from "./DropdownListItem"

export interface DropdownListProps
  extends React.HTMLAttributes<HTMLUListElement> {
  referenceElementRef: RefObject<HTMLElement>
  hasAutoWidth?: boolean
}

const DropdownListWithRef = React.forwardRef<
  HTMLUListElement,
  DropdownListProps
>(
  (
    { className, children, referenceElementRef, hasAutoWidth = true, ...props },
    forwardedRef,
  ): ReactNode => {
    const [localPopperElement, setLocalPopperElement] =
      useState<HTMLUListElement | null>(null)

    useLayoutEffect(() => {
      if (
        hasAutoWidth &&
        localPopperElement != null &&
        referenceElementRef.current != null
      ) {
        const referenceRect =
          referenceElementRef.current.getBoundingClientRect()
        localPopperElement.style.width = `${referenceRect.width}px`
      }
    })

    const { styles, attributes } = usePopper(
      referenceElementRef.current,
      localPopperElement,
    )

    const handleStoreRef = (element: HTMLUListElement): void => {
      setLocalPopperElement(element)

      if (forwardedRef) {
        if (typeof forwardedRef === "function") {
          forwardedRef(element)
        } else {
          forwardedRef.current = element
        }
      }
    }

    return (
      <ul
        ref={handleStoreRef}
        {...props}
        {...attributes.popper}
        style={{ ...styles.popper, ...props.style }}
        className={classNames("DropdownList", className)}
      >
        {children}
      </ul>
    )
  },
)

DropdownListWithRef.displayName = "DropdownList"

export const DropdownList = Object.assign(DropdownListWithRef, {
  Item: DropdownListItem,
})

import type { MouseEvent, ReactElement } from "react"
import { useEffect, useState } from "react"
import type { ButtonProps } from "shared/components/Button/Button"
import { Button } from "shared/components/Button/Button"

export function ConfirmButton({
  children,
  onClick,
  ...props
}: ButtonProps): ReactElement {
  const [confirmClick, setConfirmClick] = useState(false)
  let confirmTimeout: NodeJS.Timeout | null = null

  useEffect(() => {
    return () => {
      if (confirmTimeout) {
        clearTimeout(confirmTimeout)
      }
    }
  }, [])

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (props.isDisabled || !onClick) return
    if (confirmTimeout) {
      clearTimeout(confirmTimeout)
    }
    if (!confirmClick) {
      setConfirmClick(true)
      confirmTimeout = setTimeout(() => {
        setConfirmClick(false)
      }, 5000)
    } else {
      if (confirmClick) {
        setConfirmClick(false)
      }
      onClick(event)
    }
  }

  const displayChildren = confirmClick ? "Confirmer" : children

  return (
    <Button {...props} onClick={handleOnClick}>
      {displayChildren}
    </Button>
  )
}

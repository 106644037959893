import { RequireAuth } from "app/router/RequireAuth"
import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"

import { ClientsPage } from "./pages/ClientsPage"

export function ClientsRouter(): ReactElement {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route path={routes.clients.index} element={<ClientsPage />} />
      </Route>
    </Routes>
  )
}

import "./BillingModeSwitchAction.scss"

import classNames from "classnames"
import type { ReactNode } from "react"
import { Button } from "shared/components/Button/Button"
import type { BillingMode } from "shared/services/interfaces"

import type { BillingModeSwitchProps } from "./BillingModeSwitch"

export interface BillingModeSwitchActionProps extends BillingModeSwitchProps {
  children: string
  value: BillingMode
  hasDiscount?: boolean
}

export function BillingModeSwitchAction({
  value,
  billingMode,
  onChange,
  children,
  hasDiscount,
}: BillingModeSwitchActionProps): ReactNode {
  return (
    <Button
      isRounded
      color={billingMode == value ? undefined : "grey"}
      className={classNames("BillingModeSwitchAction", {
        "BillingModeSwitchAction--discounted": hasDiscount,
      })}
      onClick={() => onChange(value)}
    >
      {children}
    </Button>
  )
}

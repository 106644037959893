import axios from "axios"
import { useState } from "react"
import type {
  UseDeleteProperties,
  UseDeleteReturnType,
} from "shared/services/interfaces"

import { useAuth } from "../auth/useAuth"

export function useDelete<T>(
  url: string,
  {
    shouldThrowOnError = true,
    contentType = "application/json",
    params,
  }: UseDeleteProperties<T>,
): UseDeleteReturnType<T> {
  const [data, setData] = useState<T | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const { authCurrentUser } = useAuth()

  const remove = async () => {
    setIsLoading(true)
    const { idToken } = await authCurrentUser()
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/${url}`,
        {
          headers: {
            "Content-Type": contentType,
            Authorization: idToken,
          },
          data: params, // Inclusion des paramètres si nécessaires
        },
      )
      if (!shouldThrowOnError && response.status !== 200) {
        throw new Error(response.statusText)
      }
      setData(response.data)
      setHasError(false)
    } catch (error) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { data, isLoading, hasError, remove }
}

import type {
  ReturnDocument,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"

import { useGet } from "../core/useGet"

export interface UseGetDocumentVariables {
  documentId?: string
}

type UseGetDocumentUrlProperties = UseGetProperties<UseGetDocumentVariables>

export function useGetDocument({
  params,
  ...options
}: UseGetDocumentUrlProperties): UseGetReturnType<ReturnDocument> {
  const url = `document/${params?.documentId ?? ""}`
  return useGet(url, options)
}

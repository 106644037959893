import type {
  UsePostProperties,
  UsePostReturnType,
} from "shared/services/interfaces"

import { usePost } from "../core/usePost"

type UserCompanyLogo = ArrayBuffer

export type UseUpdateUserLogoProperties = UsePostProperties<UserCompanyLogo>

export function useUpdateUserLogo(
  options?: UseUpdateUserLogoProperties,
): UsePostReturnType<string, UserCompanyLogo> {
  return usePost<string, UserCompanyLogo>(`user/logo`, {
    contentType: "application/octet-stream",
    ...options,
  })
}

import "./Illustration.scss"

import classNames from "classnames"
import { type ReactElement } from "react"

interface IllustrationProps {
  className?: string
  svg: string
  alt?: string
}

export function Illustration({
  svg,
  className,
  alt,
}: IllustrationProps): ReactElement {
  return (
    <figure className={classNames("Illustration", className)}>
      <img className="Illustration__image" src={svg} alt={alt} />
    </figure>
  )
}

import type { Articles } from "./Article"
import type { Client } from "./Clients"
import type { UserCompany } from "./UserCompany"

export enum DocumentModeEnum {
  Invoice = "Facture",
  Quote = "Devis",
  Estimate = "Estimation",
  ExpenseNote = "Note de débours",
}

export enum PaymentModeEnum {
  BankTransfer = "Virement bancaire",
  Other = "Autre",
}

export interface PaymentModeTransfer {
  name: PaymentModeEnum.BankTransfer
  iban: string
  bic: string
}

export interface PaymentModeOther {
  name: PaymentModeEnum.Other
  details: string
}

export interface LatePenalties {
  latePenaltyRate?: number
  additionalLatePenalties?: number
}

export interface Document {
  name: string
  documentNumber: string
  type: DocumentModeEnum
  issueDate?: string
  dueDate?: string
  internalReference?: string
  clientCompany: Client
  userCompany: UserCompany
  items: Articles
  paymentMode: PaymentModeTransfer | PaymentModeOther
  hasVAT: boolean
  amountVAT?: number
  discount?: number
  latePenalties?: LatePenalties
  note?: string
  totalItems: number
  totalDiscount?: number
  totalAfterDiscount?: number
  totalTax?: number
  totalPaid?: number
  totalToPay: number
}

export interface ReturnDocument extends Document {
  id: string
  createdAt: string
  preview?: string
}

export type Documents = Document[]

export type ReturnDocuments = ReturnDocument[]

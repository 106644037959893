import "./Select.scss"

import { type CSSProperties, type ReactElement, useState } from "react"
import { IconArrowDownSFill, IconArrowUpSFill } from "shared/assets/icons"
import { Input } from "shared/components/Input/Input"

interface SelectProps {
  label: string
  value: string
  name?: string
  className?: string
  isDisabled?: boolean
  hasError?: boolean
  style?: CSSProperties
  options?: string[]
  onChange: (value: string) => void
}

export function Select({
  className,
  isDisabled,
  hasError,
  label,
  style,
  value,
  name,
  options = [],
  onChange = () => {},
}: SelectProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  const handleFocus = () => {
    setIsOpen(true)
  }

  const handleSelectChange = (selectedItem: string) => {
    onChange(selectedItem)
    setIsOpen(false)
  }

  const icon = isOpen ? <IconArrowUpSFill /> : <IconArrowDownSFill />

  return (
    <Input
      value={value}
      name={name}
      label={label}
      style={style}
      className={`Select ${className}`}
      suggestions={options}
      isDisabled={isDisabled}
      hasError={hasError}
      renderSuggestion={option => <>{option}</>}
      icon={icon}
      readOnly={true}
      onChange={() => {}}
      onFocus={handleFocus}
      onSelectSuggestion={handleSelectChange}
    />
  )
}

import type { ReactElement } from "react"
import { Field } from "react-final-form"
import { Textarea } from "shared/components/Textarea/Textarea"

interface AddressFieldProps {
  name: string
  value?: string
}

export function AddressField({ name, value }: AddressFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Textarea
          label="Adresse postale"
          value={props.input.value || value}
          name={props.input.name}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

import { StyleSheet } from "@react-pdf/renderer"

export const styles = StyleSheet.create({
  DocumentHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  DocumentHeaderLeft: {
    display: "flex",
    flexDirection: "column",
    flex: 5,
    gap: 4,
  },
  DocumentHeaderUserCompanyLogo: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
  DocumentHeaderUserCompanyName: {
    fontWeight: "semibold",
  },
  DocumentHeaderName: {
    fontSize: 16,
    fontWeight: "semibold",
    marginTop: 30,
  },
  DocumentHeaderRight: {
    display: "flex",
    flex: 4,
    flexDirection: "column",
    textAlign: "right",
    alignItems: "flex-end",
  },
  DocumentType: {
    fontSize: 14,
    fontWeight: "semibold",
    color: "#545454",
    marginBottom: 10,
  },
  DocumentMeta: {
    flexDirection: "column",
    width: "100%",
  },
  DocumentMetaLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  DocumentMetaLabel: {
    display: "flex",
    flex: 1,
    fontWeight: "semibold",
  },
  DocumentMetaValue: {
    display: "flex",
    flex: 1,
    fontWeight: "normal",
  },
  DocumentHeaderClient: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    gap: 2,
    border: "1pt solid #cccccc",
    padding: "12pt 16pt",
    textAlign: "left",
  },
  DocumentHeaderClientName: {
    fontWeight: "semibold",
  },
})

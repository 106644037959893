import "./Navigation.scss"

import { routes } from "app/router/routes"
import classNames from "classnames"
import { TabItem } from "layout/TabItem/TabItem"
import { type ReactElement } from "react"
import { NavLink } from "react-router-dom"
import logoDefault from "shared/assets/illustrations/logo.svg"
import logoBasic from "shared/assets/illustrations/logo-basic.svg"
import logoBusiness from "shared/assets/illustrations/logo-business.svg"
import logoPlus from "shared/assets/illustrations/logo-plus.svg"
import { Illustration } from "shared/components/Illustration/Illustration"
import { useApplicationLayoutContext } from "shared/contexts/ApplicationLayout/useApplicationLayoutContext"
import type {
  AnonUserData,
  PlanTier,
  UserData,
} from "shared/services/interfaces"

import { UserBlock } from "../../shared/components/UserBlock/UserBlock"

interface NavigationViewProps {
  user: UserData | AnonUserData
  isAuthenticated: boolean
  isNavFixed?: boolean
}

const tierLogoMapping: Record<PlanTier, string> = {
  free: logoDefault,
  plus: logoPlus,
  basic: logoBasic,
  business: logoBusiness,
}

function getLogoByTier(tier: PlanTier) {
  return tierLogoMapping[tier] || logoDefault
}

export function NavigationView({
  user,
  isAuthenticated,
  isNavFixed,
}: NavigationViewProps): ReactElement {
  const { components } = useApplicationLayoutContext()

  return (
    <header
      className={classNames("Navigation", {
        "Navigation--with-right-panel": components.RIGHT_PANEL,
        "Navigation--fixed": isNavFixed,
      })}
    >
      <div className="Navigation__content">
        <NavLink to={routes.home.index}>
          <Illustration
            className="Navigation__content__logo"
            svg={
              isAuthenticated
                ? getLogoByTier((user as UserData).plan.tier)
                : logoDefault
            }
          />
        </NavLink>

        <nav className="Navigation__content__links">
          {isAuthenticated ? (
            <TabItem to={routes.documents.create}>Nouveau document</TabItem>
          ) : (
            <TabItem to={routes.documents.publicCreate}>
              Nouveau document
            </TabItem>
          )}
          <TabItem to="/documents/history">Historique</TabItem>
        </nav>

        <UserBlock
          givenName={isAuthenticated ? (user as UserData).givenName : undefined}
          credits={
            isAuthenticated
              ? (user as UserData).plan.credits
              : (user as AnonUserData).credits
          }
          picture={isAuthenticated ? (user as UserData).picture : undefined}
        />
      </div>
    </header>
  )
}

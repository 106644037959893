import type {
  Companies,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"
import { formatPappersCompany } from "shared/utils/formatPappersCompany"

import { useGetPappers } from "../core/useGetPappers"

export function useGetCompaniesSuggestions(
  query: string,
  options: UseGetProperties = {},
): UseGetReturnType<Companies> {
  const { data, isLoading, hasError, refetch } = useGetPappers(query, options)

  let formattedData: Companies | null = null

  if (data) {
    const allResults = [
      ...(data.resultats_nom_entreprise || []),
      ...(data.resultats_siren || []),
    ]

    formattedData = allResults.map(formatPappersCompany)
  }

  return {
    data: formattedData,
    isLoading,
    hasError,
    refetch,
  }
}

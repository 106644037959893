import type { ReactElement } from "react"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { Button } from "shared/components/Button/Button"
import { CustomModal } from "shared/components/CustomModal/CustomModal"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import { useGetUserData } from "shared/services/hooks/user/useGetUserData"
import { useUpdateUserData } from "shared/services/hooks/user/useUpdateUserData"

export function TermsModal(): ReactElement {
  const { signOut } = useAuth()

  const { mutate: updateUserData, isLoading: isUpdatingUserData } =
    useUpdateUserData()

  const { data, isLoading, hasError, refetch } = useGetUserData()

  const handleAcceptTermes = () => {
    return updateUserData({ terms: true })
  }
  const handleRejectTerms = () => {
    return signOut()
  }

  const requestsResultsBatch = useBatchAppError({
    data,
    hasError,
    retry: refetch,
  })

  return (
    <BlockWithRequestsBatch
      isLoading={isLoading}
      requestsResultsBatch={requestsResultsBatch}
      renderWhenData={data =>
        !data.terms && (
          <CustomModal
            title="Termes et Conditions"
            primaryAction={
              <Button
                isDisabled={isUpdatingUserData}
                onClick={handleAcceptTermes}
              >
                J'accepte
              </Button>
            }
            secondaryAction={
              <Button
                variant="outlined"
                isDisabled={isUpdatingUserData}
                onClick={handleRejectTerms}
              >
                Je refuse
              </Button>
            }
          >
            <p>
              En cliquant sur "Accepter", vous reconnaissez avoir lu et compris
              nos{" "}
              <a href="/cgu-latest.pdf">Conditions Générales d'Utilisation</a>,
              nos <a href="/cgv-latest.pdf">Conditions Générales de Vente</a> et
              notre{" "}
              <a href="/privacy-policy-latest.pdf">
                Politique de Confidentialité
              </a>
              .
            </p>
            <p>
              Pour utiliser nos services vous devez accepter de vous conformer à
              ces termes et à les utiliser conformément à ces conditions.
            </p>
          </CustomModal>
        )
      }
    />
  )
}

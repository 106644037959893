import "./LoginPage.scss"

import type { ReactElement } from "react"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { CloudinaryImage } from "shared/components/CloudinaryImage/CloudinaryImage"
import { PageTitle } from "shared/components/PageTitle/PageTitle"
import { SignInWithGoogleButton } from "shared/components/SignInWithGoogleButton/SignInWithGoogleButton"
import { useAuth } from "shared/services/hooks/auth/useAuth"

const covers = [
  "autumnForest",
  "cyberpunkCity",
  "greenFields",
  "harbour",
  "jungle",
  "miamiNight",
  "motherboard",
  "paradiseResort",
  "pins",
  "poppyFields",
  "snowMountainsBase",
  "washingtonDc",
  "handDraw",
  "synthwaveStreet",
]

const getRandomCover = (previousCover?: string): string => {
  // Remove previous cover from array in order to prevent successives covers
  const newCovers = previousCover
    ? covers.filter(cover => cover !== previousCover)
    : covers

  // get new random cover
  return newCovers[Math.floor(Math.random() * newCovers.length)]
}

export function LoginPage(): ReactElement {
  const [randomCoverFileName, setRandomCoverFileName] =
    useState<string>(getRandomCover)

  const handleCoverClick = () => {
    setRandomCoverFileName(getRandomCover)
  }

  const { signIn, isAuthenticated } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const from: string = location.state?.from?.pathname || "/"

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true })
    }
  }, [isAuthenticated, navigate, from])

  const handleLogin = () => {
    signIn()
  }

  return (
    <>
      <PageTitle title="Connexion" />
      <div className="LoginPage">
        <div className="LoginPage__form">
          <div className="LoginPage__form__labels">
            <h1>Bienvenue,</h1>
            <p>
              Connectez-vous ou inscrivez-vous à l'aide de votre compte Google
              pour continuer.
            </p>
          </div>
          <SignInWithGoogleButton
            label="Continuer avec Google"
            onClick={handleLogin}
          />
        </div>

        <picture className="LoginPage__cover">
          <CloudinaryImage
            widthAuto={{
              fallbackWidth: 1024,
              steps: 10,
            }}
            filename={randomCoverFileName}
            onClick={handleCoverClick}
          />
        </picture>
      </div>
    </>
  )
}

import type { ReactElement } from "react"
import { Field } from "react-final-form"
import { Textarea } from "shared/components/Textarea/Textarea"

interface NoteFieldProps {
  name: string
  value?: string
}

export function NoteField({ name, value }: NoteFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Textarea
          label="Note"
          value={props.input.value || value}
          name={props.input.name}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

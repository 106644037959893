import "./FormProgressBar.scss"

import classNames from "classnames"
import { type ReactElement } from "react"

export interface FormProgressBarProps {
  completedFieldsCount: number
  totalFieldsCount: number
}

export function FormProgressBar({
  completedFieldsCount,
  totalFieldsCount,
}: FormProgressBarProps): ReactElement {
  const isCompleted = completedFieldsCount >= totalFieldsCount

  const radius = 7 // Radius of the progress circle
  const strokeWidth = 2
  const normalizedRadius = radius - strokeWidth * 0.5
  const circumference = normalizedRadius * 2 * Math.PI
  const progress = (completedFieldsCount / totalFieldsCount) * circumference

  return (
    <>
      <div
        className={classNames("FormProgressBar", {
          "FormProgressBar--valid": isCompleted,
        })}
      >
        <span>
          {isCompleted
            ? "Valide"
            : `${completedFieldsCount}/${totalFieldsCount} champs requis`}
        </span>

        <svg
          className="FormProgressBar__progress_circle"
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            className="FormProgressBar__progress_circle__background"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset: 0 }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className="FormProgressBar__progress_circle__foreground"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset: circumference - progress }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          {isCompleted ? (
            // <polyline // check mark svg
            //   className="FormProgressBar__progress_circle__tick"
            //   strokeWidth={1}
            //   points="6.6,11 8.8,14.3 15.4,7.7"
            // />
            <path
              className="FormProgressBar__progress_circle__tick"
              d="M2 9l2 2 5-7"
              fill="none"
              strokeWidth={strokeWidth}
            />
          ) : null}
        </svg>

        {/* {isCompleted && (
        <Tag
          className="FormProgressBar__tag"
          label="Valide"
          color="green"
          icon={<IconCheckFill />}
        />
      )} */}
      </div>
      {/* <Tag label="Valide" color="green"></Tag> */}
    </>
  )
}

import "./DropdownList.scss"

import classNames from "classnames"
import React from "react"

export interface DropdownListItemProps
  extends React.HTMLAttributes<HTMLLIElement> {
  isHighlighted?: boolean
}

export const DropdownListItem = React.forwardRef<
  HTMLLIElement,
  DropdownListItemProps
>(({ className, children, isHighlighted, ...props }, ref) => (
  <li
    ref={ref}
    className={classNames("DropdownList__item", className, {
      "DropdownList__item--highlighted": isHighlighted,
    })}
    {...props}
  >
    {children}
  </li>
))

DropdownListItem.displayName = "DropdownListItem"

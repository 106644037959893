import { useMemo } from "react"

export type RetryFunction = () => unknown | Promise<unknown>

export interface ErrorBatch<T extends unknown[] = unknown[]> {
  hasError: boolean
  retryFunctions: RetryFunction[]
  data: T
}

export interface ErrorBatchEntry<TData> {
  hasError: boolean
  retry: RetryFunction
  data: TData
  isLoading?: boolean
}

export type ErrorBatchEntryArray<T extends unknown[]> = {
  [P in keyof T]: ErrorBatchEntry<T[P]>
}

export function createBatch<T extends unknown[]>(
  entries: ErrorBatchEntryArray<T>,
): ErrorBatch<T> {
  const entriesToBatch = entries.filter(
    entry => !!entry.hasError,
  ) as ErrorBatchEntry<unknown>[]

  const batchObject = {
    get hasError() {
      return entriesToBatch.length > 0
    },
    get retryFunctions() {
      return entriesToBatch.map(entry => entry.retry)
    },
    get data() {
      return entries.map(entry => entry.data) as T
    },
    entries,
  }

  return batchObject
}

export function useBatchAppError<T extends unknown[]>(
  ...entries: ErrorBatchEntryArray<T>
): ErrorBatch<T> {
  const dependencies = []

  for (let i = 0, l = entries.length; i < l; ++i) {
    const { hasError, retry, data } = entries[i]
    dependencies.push(hasError, retry, data)
  }

  return useMemo<ErrorBatch<T>>(() => createBatch(entries), dependencies)
}

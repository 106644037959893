import { type ReactElement } from "react"
import { Field } from "react-final-form"
import { Textarea } from "shared/components/Textarea/Textarea"

interface ArticleDescriptionFieldProps {
  name: string
  value?: string
}

export function ArticleDescriptionField({
  name,
  value,
}: ArticleDescriptionFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Textarea
          label="Déscription"
          name={props.input.name}
          value={props.input.value || value}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

import "./TopLoadingBar.scss"

import type { ReactNode } from "react"

export function TopLoadingBar(): ReactNode {
  return (
    <div className="TopLoadingBar">
      <div className="TopLoadingBar__value TopLoadingBar__value--indeterminate" />
      <div className="TopLoadingBar__bar" />
    </div>
  )
}

import { Text, View } from "@react-pdf/renderer"
import type { ReactElement } from "react"
import {
  PaymentModeEnum,
  type ReturnDocument,
} from "shared/services/interfaces"

import { styles } from "./DocumentFooterStyle"

export interface DocumentFooterProps
  extends Partial<
    Pick<
      ReturnDocument,
      "latePenalties" | "paymentMode" | "note" | "userCompany"
    >
  > {
  // hasLogo: boolean
}

export function DocumentFooter({
  latePenalties,
  paymentMode,
  note,
  userCompany,
}: // hasLogo,
DocumentFooterProps): ReactElement {
  return (
    <View style={styles.DocumentFooter} fixed={true}>
      <Text>{note}</Text>

      {paymentMode && (
        <View style={styles.DocumentFooterPayment}>
          <View style={styles.DocumentFooterPaymentRow}>
            <Text style={styles.DocumentFooterPaymentRowLabel}>
              Mode de règlement :
            </Text>
            <Text>{paymentMode.name}</Text>
          </View>

          <View style={styles.DocumentFooterPaymentRow}>
            {paymentMode.name === PaymentModeEnum.BankTransfer && (
              <>
                <Text style={styles.DocumentFooterPaymentRowLabel}>
                  Coordonnées bancaires :{" "}
                </Text>
                <Text>IBAN : {paymentMode.iban}</Text>
                <Text>BIC / Swift Code: {paymentMode.bic}</Text>
              </>
            )}
            {paymentMode.name === PaymentModeEnum.Other && (
              <>
                <Text style={styles.DocumentFooterPaymentRowLabel}>
                  Details :{" "}
                </Text>
                <Text>{paymentMode.details}</Text>
              </>
            )}
          </View>
        </View>
      )}

      {latePenalties?.latePenaltyRate ? (
        <Text>
          Taux de pénalité : {latePenalties.latePenaltyRate.toFixed(2)} %
        </Text>
      ) : null}
      <Text>
        Indemnité forfaitaire pour frais de recouvrement en cas de retard de
        paiement : 40,00 €.
      </Text>

      {!userCompany?.hasRcs ? (
        <Text>
          Dispensé d’immatriculation au Registre du Commerce et des Sociétés
          (RCS) et au Répertoire des Métiers (RM).
        </Text>
      ) : null}

      {/* Page Number  */}

      <View style={styles.PageNumber}>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </View>
  )
}

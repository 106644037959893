import { type ReactElement } from "react"
import { useFormState } from "react-final-form"
import type { CreateDocumentFormValues } from "sections/documents/pages/CreateDocumentPage/CreateDocumentPageView"
import { FormSection } from "shared/components/FormSection/FormSection"
import { PaymentModeEnum } from "shared/services/interfaces"

import { BicField } from "./components/BicField"
import { DetailsField } from "./components/DetailsField"
import { IbanField } from "./components/IbanFiel"
import { PaymentModeField } from "./components/PaymentModeField"

interface PaymentFormProps {
  id?: string
  isExpanded?: boolean
  handleFormFocus?: () => void
}

export function PaymentForm({
  id,
  isExpanded,
  handleFormFocus,
}: PaymentFormProps): ReactElement {
  const { values } = useFormState<CreateDocumentFormValues>()
  const { paymentMode } = values

  return (
    <FormSection
      id={id}
      title="Paiement"
      isExpanded={isExpanded}
      handleFormFocus={handleFormFocus}
    >
      <PaymentModeField name="paymentMode.name" />
      {paymentMode?.name === PaymentModeEnum.BankTransfer && (
        <>
          <IbanField name="paymentMode.iban" />
          <BicField name="paymentMode.bic" />
        </>
      )}
      {paymentMode?.name === PaymentModeEnum.Other && (
        <>
          <DetailsField name="paymentMode.details" />
        </>
      )}
    </FormSection>
  )
}

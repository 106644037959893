import { Layout } from "layout/Layout/Layout"
import type { ReactNode } from "react"
import { createContext, useCallback, useMemo, useReducer } from "react"

export enum ApplicationLayoutComponentEnum {
  RIGHT_PANEL = "RIGHT_PANEL",
}

export enum ComponentReducerActionEnum {
  REGISTER = "REGISTER",
  UNREGISTER = "UNREGISTER",
}

interface ComponentReducerAction {
  type: ComponentReducerActionEnum
  key: ApplicationLayoutComponentEnum
}

type ComponentReducerState = Partial<
  Record<ApplicationLayoutComponentEnum, boolean>
>

export interface ApplicationLayoutContextReturnObject {
  components: ComponentReducerState
  registerComponent: (key: ApplicationLayoutComponentEnum) => void
  unregisterComponent: (key: ApplicationLayoutComponentEnum) => void
}

export const ApplicationLayoutContext =
  createContext<ApplicationLayoutContextReturnObject>({
    components: {},
    registerComponent: () => {},
    unregisterComponent: () => {},
  })

function componentsReducer(
  state: ComponentReducerState,
  action: ComponentReducerAction,
) {
  switch (action.type) {
    case ComponentReducerActionEnum.REGISTER: {
      return { ...state, [action.key]: true }
    }
    case ComponentReducerActionEnum.UNREGISTER: {
      return { ...state, [action.key]: false }
    }
    default: {
      return state
    }
  }
}

interface ApplicationLayoutContextProps {
  children: ReactNode
}

export function ApplicationLayoutContextProvider({
  children,
}: ApplicationLayoutContextProps): ReactNode {
  const [components, dispatch] = useReducer(componentsReducer, {})

  const registerComponent = useCallback(
    (key: ApplicationLayoutComponentEnum) =>
      dispatch({ type: ComponentReducerActionEnum.REGISTER, key }),
    [],
  )

  const unregisterComponent = useCallback(
    (key: ApplicationLayoutComponentEnum) =>
      dispatch({ type: ComponentReducerActionEnum.UNREGISTER, key }),
    [],
  )

  const value = useMemo(
    () => ({ components, registerComponent, unregisterComponent }),
    [components, registerComponent, unregisterComponent],
  )

  return (
    <ApplicationLayoutContext.Provider value={value}>
      <Layout>{children}</Layout>
    </ApplicationLayoutContext.Provider>
  )
}

import "./ItemRecap.scss"

import type { ReactElement } from "react"
import { IconEditLine, IconTrash } from "shared/assets/icons"
import { Icon } from "shared/components/Icon/Icon"
import type { Article } from "shared/services/interfaces"
import { formatPrice } from "shared/utils/formatPrice"

interface ItemRecapProps {
  item: Partial<Article>
  onEditButtonClick: () => void
  onDeleteButtonClick: () => void
}

export function ItemRecap({
  item,
  onEditButtonClick,
  onDeleteButtonClick,
}: ItemRecapProps): ReactElement {
  return (
    <div className="ItemRecap" onClick={() => onEditButtonClick()}>
      <div className="ItemRecap__actions">
        <button
          className="ItemRecap__actions__delete"
          onClick={() => onDeleteButtonClick()}
        >
          <Icon size={12} color="primary-base" icon={<IconTrash />} />
        </button>
        <button
          className="ItemRecap__actions__edit"
          onClick={() => onEditButtonClick()}
        >
          <Icon size={12} color="primary-base" icon={<IconEditLine />} />
        </button>
      </div>
      <div className="ItemRecap__infos">
        <span className="ItemRecap__infos__name">
          {item.name || "Article sans nom"}
        </span>
        <span className="ItemRecap__infos__quantity">
          {item.quantity} {item.unit}
        </span>
        <span className="ItemRecap__infos__total">
          {item.quantity && item.unitPrice
            ? formatPrice(item.quantity * item.unitPrice)
            : "- €"}
        </span>
      </div>
    </div>
  )
}

import { type ReactElement } from "react"
import { useFormState } from "react-final-form"
import type { CreateDocumentFormValues } from "sections/documents/pages/CreateDocumentPage/CreateDocumentPageView"
import { FormSection } from "shared/components/FormSection/FormSection"

import { DocumentTypeField } from "./components/DocumentTypeField"
import { DueDateField } from "./components/DueDateField"
import { IssueDateField } from "./components/IssueDateField"
import { NameField } from "./components/NameField"
import { NumberField } from "./components/NumberField"
import { ReferenceField } from "./components/ReferenceField"

interface DocumentTypeFormProps {
  id?: string
  isExpanded?: boolean
  handleFormFocus?: () => void
}

export function DocumentTypeForm({
  id,
  isExpanded,
  handleFormFocus,
}: DocumentTypeFormProps): ReactElement {
  const { values } = useFormState<CreateDocumentFormValues>()
  const { name, type, internalReference, documentNumber, issueDate, dueDate } =
    values

  const requiredFields: (keyof CreateDocumentFormValues)[] = [
    "name",
    "documentNumber",
    "type",
    "issueDate",
    "dueDate",
  ]
  const completedFieldsCount = requiredFields.reduce((count, fieldName) => {
    if (values[fieldName]) {
      return Number(count) + 1
    }
    return count
  }, 0)

  return (
    <FormSection
      id={id}
      title="Document"
      isExpanded={isExpanded}
      handleFormFocus={handleFormFocus}
      progress={{
        totalFieldsCount: requiredFields.length,
        completedFieldsCount: completedFieldsCount,
      }}
    >
      <NameField name="name" value={name} />
      <DocumentTypeField name="type" value={type} />
      <FormSection.Row>
        <NumberField name="documentNumber" value={documentNumber} />
        <ReferenceField name="internalReference" value={internalReference} />
      </FormSection.Row>
      <FormSection.Row>
        <IssueDateField name="issueDate" value={issueDate} />
        <DueDateField name="dueDate" value={dueDate} />
      </FormSection.Row>
    </FormSection>
  )
}

import { type ReactElement, useState } from "react"
import { useForm, useFormState } from "react-final-form"
import type { CreateDocumentFormValues } from "sections/documents/pages/CreateDocumentPage/CreateDocumentPageView"
import { IconAddCircleLine } from "shared/assets/icons"
import { Button } from "shared/components/Button/Button"
import { FormSection } from "shared/components/FormSection/FormSection"
import { Icon } from "shared/components/Icon/Icon"

import { ItemElement } from "./components/ItemElement/ItemElement"

interface ItemsFormProps {
  id?: string
  isExpanded?: boolean
  handleFormFocus?: () => void
}

export function ItemsForm({
  id,
  isExpanded,
  handleFormFocus,
}: ItemsFormProps): ReactElement {
  const { change } = useForm()
  const { values } = useFormState<CreateDocumentFormValues>()
  const { items } = values

  const [activeItem, setActiveItemIndex] = useState<number | null>(0)

  const handleAddItem = () => {
    const updatedItems = [...(items || []), {}]
    change("items", updatedItems)
    setActiveItemIndex(updatedItems.length - 1)
  }

  const handleDeleteItem = (index: number) => {
    const updatedItems = items?.filter((_, i) => i !== index) || []
    change("items", updatedItems)
    if (activeItem === index) {
      setActiveItemIndex(null)
    }
  }

  return (
    <FormSection
      id={id}
      isExpanded={isExpanded}
      handleFormFocus={handleFormFocus}
      title="Articles"
      className="ItemsForm"
    >
      {items?.map((item, index) => (
        <ItemElement
          key={index}
          itemNumber={index}
          item={item}
          isActive={index === activeItem}
          onDeleteButtonClick={() => handleDeleteItem(index)}
          onEditButtonClick={() => setActiveItemIndex(index)}
        />
      ))}
      <Button
        variant="link"
        icon={<Icon icon={<IconAddCircleLine />} />}
        iconPosition="left"
        onClick={handleAddItem}
      >
        Ajouter un article
      </Button>
    </FormSection>
  )
}

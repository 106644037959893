import type React from "react"
import type { ReactNode } from "react"
import { createContext, useContext } from "react"
import type { UseAuth } from "shared/services/interfaces"

import { useProvideAuth } from "./useProvideAuth"

interface ProviderAuthProps {
  children?: React.ReactNode
}

export function ProvideAuth({ children }: ProviderAuthProps): ReactNode {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

const authContext = createContext({} as UseAuth)

export const useAuth = (): UseAuth => {
  return useContext(authContext)
}

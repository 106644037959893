import axios from "axios"
import type {
  CompaniesPappersResponse,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"
import useSWR from "swr"

export function useGetPappers<T = CompaniesPappersResponse>(
  query: string,
  {
    shouldSkip,
    shouldThrowOnError = true,
    contentType = "application/json",
  }: UseGetProperties,
): UseGetReturnType<T> {
  const fetcher = async (query: [string, string]) => {
    if (shouldSkip) return

    const response = await axios.get(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${process.env.REACT_APP_PAPPERS_API}/v2?cibles=nom_entreprise,siren&q=${query}`,
      {
        headers: {
          "Content-Type": contentType,
        },
      },
    )

    if (!shouldThrowOnError && response.status !== 200) {
      throw new Error(response.statusText)
    }

    return response.data
  }

  const {
    data: fetchedData,
    error: fetchError,
    isLoading: fetchLoading,
    mutate,
  } = useSWR<T | null | undefined>(shouldSkip ? null : query, fetcher as any)

  const hasError = !!fetchError

  return {
    data: fetchedData ?? null,
    isLoading: fetchLoading,
    hasError,
    refetch: () => {
      mutate()
      return Promise.resolve()
    },
  }
}

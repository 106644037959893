import { type ReactElement } from "react"
import { Field } from "react-final-form"
import { Input } from "shared/components/Input/Input"

interface ArticleNameFieldProps {
  name: string
  value?: string
  inputRef?: React.RefObject<HTMLInputElement> | null
}

export function ArticleNameField({
  name,
  value,
  inputRef,
}: ArticleNameFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Input
          label="Nom"
          name={props.input.name}
          value={props.input.value || value}
          inputRef={inputRef}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

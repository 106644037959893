import type {
  UseGetProperties,
  UseGetReturnType,
  UserData,
} from "shared/services/interfaces"

import { useGet } from "../core/useGet"

export function useGetUserData(
  options: UseGetProperties = {},
): UseGetReturnType<UserData> {
  return useGet("user", options)
}

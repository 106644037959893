import "./Textarea.scss"

import classNames from "classnames"
import type {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  ReactElement,
} from "react"
import { useState } from "react"

import { Icon } from "../Icon/Icon"

export interface TextareaProps {
  label: string
  value: string
  name?: string
  className?: string
  isDisabled?: boolean
  hasError?: boolean
  style?: CSSProperties
  icon?: ReactElement
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void
}

export function Textarea({
  className,
  isDisabled,
  hasError,
  label,
  style,
  value,
  icon,
  name,
  onBlur,
  onFocus,
  onChange,
}: TextareaProps): ReactElement {
  const [isFocused, setFocused] = useState(false)
  const isFilled = !!value

  const inputClass = classNames("Textarea__container", className, {
    "Textarea__container--default": !isDisabled && !hasError,
    "Textarea__container--default-filled":
      !hasError && !isDisabled && isFilled && !isFocused,
    "Textarea__container--error": hasError && !isDisabled,
    "Textarea__container--disabled": isDisabled,
  })

  const labelClass = classNames({
    "Textarea__label--focused": value || isFocused,
    "Textarea__label--default": !value && !isFocused,
    "Textarea__label--disabled":
      (!hasError && !isDisabled && !isFocused) || isDisabled,
  })

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>): void => {
    setFocused(false)
    if (onBlur) onBlur(event)
  }

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>): void => {
    setFocused(true)
    if (onFocus) onFocus(event)
  }

  return (
    <div className={inputClass} style={style}>
      <label className={labelClass}>{label}</label>

      <textarea
        value={value}
        name={name}
        data-1p-ignore={true} // 1p workaround
        data-lpignore={true} // dashlane workaround
        data-form-type={true} // dashlane workaround
        disabled={isDisabled}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {icon ? (
        <Icon
          color="grey-mid"
          size={12}
          icon={icon}
          className="Textarea__icon"
        />
      ) : null}
    </div>
  )
}

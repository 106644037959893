import { type ReactElement } from "react"
import { Field } from "react-final-form"
import { Select } from "shared/components/Select/Select"
import { PaymentModeEnum } from "shared/services/interfaces"

interface PaymentModeFielddProps {
  name: string
  value?: string
}

const paymentOptions: string[] = Object.values(PaymentModeEnum)

export function PaymentModeField({
  name,
  value,
}: PaymentModeFielddProps): ReactElement {
  return (
    <Field name={name}>
      {props => {
        return (
          <>
            <Select
              label="Mode de règlement"
              value={props.input.value || value}
              options={paymentOptions}
              name={props.input.name}
              onChange={props.input.onChange}
            />
          </>
        )
      }}
    </Field>
  )
}

import { useState } from "react"
import { Field } from "react-final-form"
import { Input } from "shared/components/Input/Input"

interface ArticleUnitPriceFieldProps {
  name: string
  value?: number
  className?: string
}

export function ArticleUnitPriceField({
  name,
  value,
  className,
}: ArticleUnitPriceFieldProps): React.ReactElement {
  const [localValue, setLocalValue] = useState(value || 0)

  return (
    <Field name={name}>
      {props => {
        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const euroValue = parseFloat(e.target.value)
          setLocalValue(euroValue)
          // Convert to cents
          const centValue = Math.round(euroValue * 100)

          // Update form state
          props.input.onChange(centValue)
        }

        return (
          <Input
            className={className}
            label="Prix unit."
            name={props.input.name}
            value={localValue ? localValue.toString() : ""}
            onChange={handleInputChange}
          />
        )
      }}
    </Field>
  )
}

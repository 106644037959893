import "./Table.scss"

import type {
  ColumnDef,
  SortingState,
  TableOptions,
} from "@tanstack/react-table"
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import type { ReactElement } from "react"
import { useState } from "react"
import { IconSortAsc, IconSortDesc } from "shared/assets/icons"

import { Icon } from "../Icon/Icon"

interface TableProps<TData extends object> {
  columns: ColumnDef<TData>[]
  data: TData[]
  customOptions?: Partial<Omit<TableOptions<TData>, "columns" | "data">>
}
export function Table<TData extends object>({
  columns,
  data,
  customOptions = {},
}: TableProps<TData>): ReactElement {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "createdAt", desc: true },
  ])

  const table = useReactTable<TData>({
    columns,
    data,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    ...customOptions,
  })

  return (
    <table className="Table">
      <thead className="Table__Head">
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className="Table__Head__Row">
            {headerGroup.headers.map(header => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <div
                    className="Table__Head__Row__Cell"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <span className="Table__Head__Row__Cell__Label">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </span>
                    <span className="Table__Head__Row__Cell__Icon">
                      {
                        {
                          asc: <Icon icon={<IconSortAsc />} />,
                          desc: <Icon icon={<IconSortDesc />} />,
                        }[header.column.getIsSorted() as string]
                      }
                    </span>
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="Table__Body">
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className="Table__Body__Row">
            {row.getVisibleCells().map(cell => (
              <td key={cell.id} className="Table__Body__Row__Cell">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

import "./PlanItem.scss"

import classNames from "classnames"
import type { ReactElement } from "react"
import { IconCheckboxCircleFill, IconFileCopy2Line } from "shared/assets/icons"
import { Button } from "shared/components/Button/Button"
import { Icon } from "shared/components/Icon/Icon"
import type { BenefitItem } from "shared/services/interfaces"

import { Tag } from "../Tag/Tag"

export interface PlanItemProps {
  name: string
  tier: string
  isCurrent: boolean
  tagline: string
  isPopular: boolean
  description: string
  amount: number
  freq: string
  credits: string | number
  benefits: BenefitItem[]
  expiration?: number
  isDisabled?: boolean
  onManageButtonClick: () => Promise<void>
}

type FreqMappingType = {
  [key in "month" | "year"]: string
}

const freqMapping: FreqMappingType = {
  month: "mois",
  year: "an",
}

export function PlanItem({
  name,
  tier,
  isCurrent,
  tagline,
  isPopular,
  description,
  amount,
  freq,
  credits,
  benefits,
  expiration,
  isDisabled,
  onManageButtonClick,
}: PlanItemProps): ReactElement {
  const localFreq = freqMapping[freq as keyof FreqMappingType]
  const isUnlimited = credits === "unlimited"
  const isExpiring = expiration && isCurrent
  const expirationDate =
    isExpiring && new Date(expiration * 1000).toLocaleDateString()

  return (
    <div
      className={classNames("PlanItem", {
        "PlanItem--isCurrent": isCurrent,
      })}
    >
      {isCurrent && !isExpiring && (
        <div className="PlanItem__Header__Tag PlanItem__Header__Tag--status">
          Actif
        </div>
      )}
      {isExpiring && (
        <div className="PlanItem__Header__Tag PlanItem__Header__Tag--status">
          Expire le {expirationDate}
        </div>
      )}
      {!isCurrent && isPopular && (
        <div className="PlanItem__Header__Tag PlanItem__Header__Tag--featured">
          Populaire
        </div>
      )}
      <div className="PlanItem__Header">
        <h2>{tier}</h2>
        <div className="PlanItem__Header__Tagline">{tagline}</div>
      </div>

      <div className="PlanItem__Pricing">
        <div className="PlanItem__Pricing__Price">
          <div className="PlanItem__Pricing__Price__Number">
            {amount / 100} €
          </div>

          <div className="PlanItem__Pricing__Price__Rate">/ {localFreq}</div>
        </div>
        <p className="PlanItem__Pricing__Description">{description}</p>
      </div>
      <div className="PlanItem__Docs">
        <Icon icon={<IconFileCopy2Line />} color="grey-mid" />
        <p>
          {isUnlimited
            ? "Documents illimités"
            : `${credits} documents / ${localFreq}`}
        </p>
      </div>
      <div className="PlanItem__Benefits">
        {benefits.map((benefit, index) => {
          return (
            <div key={index} className="PlanItem__Benefits__Item">
              <Icon icon={<IconCheckboxCircleFill />} color="grey-mid" />
              {benefit.name}
              {benefit.isComingSoon ? <Tag label="Soon" color="blue" /> : null}
            </div>
          )
        })}
      </div>

      <div className="PlanItem__CTA">
        {isCurrent ? (
          <Button
            variant="subtle"
            isDisabled={isDisabled}
            onClick={onManageButtonClick}
          >
            Gérer mon abonnement
          </Button>
        ) : (
          <Button
            variant="outlined"
            isDisabled={isDisabled}
            onClick={onManageButtonClick}
          >{`Passer à ${name}`}</Button>
        )}
      </div>
    </div>
  )
}

import "./ClientsPage.scss"

import type { ReactElement } from "react"
import { Button } from "shared/components/Button/Button"
import { PageTitle } from "shared/components/PageTitle/PageTitle"
import { useCreateClient } from "shared/services/hooks/clients/useCreateClient"
import { useGetClients } from "shared/services/hooks/clients/useGetClients"
import { clientMock } from "shared/services/mocks/clientsMocks"

import { ClientBlock } from "./components/ClientBlock"

export function ClientsPage(): ReactElement {
  const {
    data: clients,
    isLoading,
    hasError,
    refetch: refetchClients,
  } = useGetClients()

  const { mutate: createClient, isLoading: isCreatingClient } =
    useCreateClient()

  const handleCreateNewClient = () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    createClient(
      {
        name: clientMock.name,
        contact: clientMock.contact,
        email: clientMock.email,
        postalAddress: clientMock.postalAddress,
        vatNumber: clientMock.vatNumber,
        phone: clientMock.phone,
        siret: clientMock.siret,
      },
      {
        onError: () => {
          console.error("Erreur lors de la création d'un nouveau client")
        },
        onSuccess: () => {
          console.log("Nouveau client créé avec succès")
          refetchClients()
        },
      },
    )
  }

  // TODO: generic loading/error component
  if (isLoading) return <div>Loading...</div>
  if (hasError) return <div>Something went wrong</div>
  if (!clients) return <div>No data</div>

  return (
    <>
      <PageTitle title="Clients" />
      <main className="ClientsPage">
        <Button isDisabled={isCreatingClient} onClick={handleCreateNewClient}>
          Create Client
        </Button>

        <div className="ClientsPage__List">
          {clients.map(client => {
            return (
              <ClientBlock
                key={client.id}
                name={client.name}
                logo={client.logo}
                postalAddress={client.postalAddress}
                siret={client.siret}
                vatNumber={client.vatNumber}
              />
            )
          })}
        </div>
      </main>
    </>
  )
}

import "./SnackBarContainer.scss"

import type { ReactNode } from "react"
import { ToastContainer } from "react-toastify"

export function SnackBarContainer(): ReactNode {
  return (
    <ToastContainer
      //Remove later
      theme="dark"
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
    />
  )
}

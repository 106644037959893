import "./FormSection.scss"

import classNames from "classnames"
import { type ReactElement, type ReactNode, useRef } from "react"
import { useOnClickOutside } from "usehooks-ts"

import type { FormProgressBarProps } from "./components/FormProgressBar/FormProgressBar"
import { FormProgressBar } from "./components/FormProgressBar/FormProgressBar"
import { FormSectionRow } from "./FormSectionRow"

export interface FormSectionProps {
  title: string
  id?: string
  children: ReactNode
  progress?: FormProgressBarProps
  className?: string
  actionLabel?: string
  actionIcon?: ReactElement
  isExpanded?: boolean
  handleFormFocus?: () => void
  onClickOutside?: () => void
}

export function FormSection({
  title,
  id,
  children,
  className,
  progress,
  isExpanded = false,
  handleFormFocus,
  onClickOutside,
}: FormSectionProps): ReactNode {
  const sectionRef = useRef<HTMLElement | null>(null)

  useOnClickOutside(sectionRef, () => {
    if (onClickOutside) onClickOutside()
  })

  // const handleToggleCollapse = () => {
  //   setIsExpanded(!isExpanded)
  // }

  return (
    <section
      ref={sectionRef}
      id={id}
      className={classNames(
        "FormSection",
        { "FormSection--expanded": isExpanded },
        className,
      )}
    >
      <div className="FormSection__topLine" onClick={handleFormFocus}>
        <h2>{title}</h2>
        {progress ? <FormProgressBar {...progress} /> : null}
      </div>
      {isExpanded && <div className="FormSection__fields">{children}</div>}
    </section>
  )
}

FormSection.Row = FormSectionRow

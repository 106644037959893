import "./ItemsTable.scss"

import type { ReactElement } from "react"
import type { ReturnDocument } from "shared/services/interfaces"
import { formatPrice } from "shared/utils/formatPrice"

export interface ItemsTableProps
  extends Partial<
    Pick<
      ReturnDocument,
      | "items"
      | "hasVAT"
      | "amountVAT"
      | "totalItems"
      | "totalDiscount"
      | "totalAfterDiscount"
      | "totalTax"
      | "totalPaid"
      | "totalToPay"
    >
  > {}

export function ItemsTable({
  items,
  hasVAT,
  amountVAT,
  totalItems,
  totalDiscount,
  totalAfterDiscount,
  totalTax,
  totalPaid,
  totalToPay,
}: ItemsTableProps): ReactElement {
  return (
    <div className="ItemsTable">
      <div className="ItemsTable__Header">
        <div className="ItemsTable__Header__Name">Désignation</div>
        <div className="ItemsTable__Header__Qty">Qté</div>
        <div className="ItemsTable__Header__Unit">Unité</div>
        <div className="ItemsTable__Header__Price">Prix Unit. HT</div>
        <div className="ItemsTable__Header__Total">Total HT</div>
      </div>

      <div className="ItemsTable__Body">
        {items?.map((item, index) => {
          return (
            <div key={index} className="ItemsTable__Body__Item">
              <div className="ItemsTable__Body__Item__Designation">
                <p className="ItemsTable__Body__Item__Designation__Name">
                  {item.name}
                </p>
                {item.description && (
                  <p className="ItemsTable__Body__Item__Designation__Description">
                    {item.description}
                  </p>
                )}
              </div>
              <div className="ItemsTable__Body__Item__Qty">{item.quantity}</div>
              <div className="ItemsTable__Body__Item__Unit">{item.unit}</div>
              <div className="ItemsTable__Body__Item__Price">
                {formatPrice(item.unitPrice)}
              </div>
              <div className="ItemsTable__Body__Item__Total">
                {formatPrice(item.unitPrice * item.quantity)}
              </div>
            </div>
          )
        })}
      </div>

      <div className="ItemsTable__Footer">
        {!hasVAT && (
          <span className="ItemsTable__Footer_NoVAT">
            TVA Non applicable en vertu de l'art 293B du CGI
          </span>
        )}

        <div className="ItemsTable__Footer__Total">
          <span className="ItemsTable__Footer__Total__Label">Total HT</span>
          <span className="ItemsTable__Footer__Total__Value">
            {totalItems ? formatPrice(totalItems) : "-"}
          </span>
        </div>

        {totalDiscount && totalAfterDiscount ? (
          <>
            <div className="ItemsTable__Footer__Total">
              <span className="ItemsTable__Footer__Total__Label">Remise</span>
              <span className="ItemsTable__Footer__Total__Value">
                - {formatPrice(totalDiscount)}
              </span>
            </div>
            <div className="ItemsTable__Footer__Total">
              <span className="ItemsTable__Footer__Total__Label">
                Total après remise
              </span>
              <span className="ItemsTable__Footer__Total__Value">
                {formatPrice(totalAfterDiscount)}
              </span>
            </div>
          </>
        ) : null}

        {hasVAT && totalTax ? (
          <div className="ItemsTable__Footer__Total">
            <span className="ItemsTable__Footer__Total__Label">
              Total TVA ({amountVAT}%)
            </span>
            <span className="ItemsTable__Footer__Total__Value">
              {formatPrice(totalTax)}
            </span>
          </div>
        ) : null}

        {totalPaid ? (
          <div className="ItemsTable__Footer__Total">
            <span className="ItemsTable__Footer__Total__Label">Déjà Payé</span>
            <span className="ItemsTable__Footer__Total__Value">
              {formatPrice(totalPaid)}
            </span>
          </div>
        ) : null}

        <div className="ItemsTable__Footer__Total">
          <span className="ItemsTable__Footer__Total__Label">Total TTC</span>
          <span className="ItemsTable__Footer__Total__Value">
            {totalToPay ? formatPrice(totalToPay) : "-"}
          </span>
        </div>

        <div className="ItemsTable__Footer__Total ItemsTable__Footer__Total-TTC">
          <span className="ItemsTable__Footer__Total__Label">
            Montant à payer
          </span>
          <span className="ItemsTable__Footer__Total__Value">
            {totalToPay ? formatPrice(totalToPay) : "-"}
          </span>
        </div>
      </div>
    </div>
  )
}

import type { ReactNode } from "react"
import { createPortal } from "react-dom"
import { rightPanelMountingPointId } from "shared/constants"
import { ApplicationLayoutComponentEnum } from "shared/contexts/ApplicationLayout/ApplicationLayoutContext"
import { useRegisterLayoutComponents } from "shared/contexts/ApplicationLayout/useRegisterLayoutComponent"

export interface RightPanelProps {
  children?: ReactNode
}

export function RightPanel({ children }: RightPanelProps): ReactNode {
  useRegisterLayoutComponents(ApplicationLayoutComponentEnum.RIGHT_PANEL)
  const mounting = document.getElementById(rightPanelMountingPointId)

  if (mounting) {
    return createPortal(children, mounting)
  }

  return null
}

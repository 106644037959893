import { RequireAuth } from "app/router/RequireAuth"
import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"

import { ProfilePage } from "./pages/ProfilePage"

export function UserRouter(): ReactElement {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route path={routes.user.index} element={<ProfilePage />} />
      </Route>
    </Routes>
  )
}

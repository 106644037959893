import "./ItemElement.scss"

import type { ReactElement } from "react"
import { FormSection } from "shared/components/FormSection/FormSection"
import type { Article } from "shared/services/interfaces"

import { ArticleDescriptionField } from "../ArticleDescriptionField"
import { ArticleNameField } from "../ArticleNameField"
import { ArticleQtyField } from "../ArticleQtyField"
import { ArticleUnitField } from "../ArticleUnitField"
import { ArticleUnitPriceField } from "../ArticleUnitPriceField"
import { ItemRecap } from "../ItemRecap/ItemRecap"

interface ItemElementProps {
  isActive?: boolean
  itemNumber: number
  item: Partial<Article>
  onEditButtonClick: () => void
  onDeleteButtonClick: () => void
}

export function ItemElement({
  isActive,
  itemNumber,
  item,
  onEditButtonClick,
  onDeleteButtonClick,
}: ItemElementProps): ReactElement {
  return isActive ? (
    <section className="ItemElement">
      <ArticleNameField
        name={`items[${itemNumber}].name`}
        value={item?.name || ""}
      />

      <FormSection.Row>
        <ArticleQtyField
          className="ItemElement__quantity"
          name={`items[${itemNumber}].quantity`}
          value={item?.quantity && Number(item.quantity)}
        />
        <ArticleUnitField
          className="ItemElement__unit"
          name={`items[${itemNumber}].unit`}
          value={item?.unit || ""}
        />
        <ArticleUnitPriceField
          className="ItemElement__unitPrice"
          name={`items[${itemNumber}].unitPrice`}
          value={item?.unitPrice && Number(item.unitPrice)}
        />
      </FormSection.Row>

      <ArticleDescriptionField
        name={`items[${itemNumber}].description`}
        value={item?.description || ""}
      />
    </section>
  ) : (
    <ItemRecap
      item={item}
      onEditButtonClick={onEditButtonClick}
      onDeleteButtonClick={onDeleteButtonClick}
    />
  )
}

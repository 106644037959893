import { pdf } from "@react-pdf/renderer"
import { useEffect, useState } from "react"
import { PDFDocument } from "sections/documents/PDFDocument/PDFDocument"
import type { Document, UsePostProperties } from "shared/services/interfaces"

import { usePost } from "../core/usePost"

interface UseClientPrintVariables {
  documentData: Partial<Document>
}

export type UseClientPrintProperties =
  UsePostProperties<UseClientPrintVariables> & {
    isPublic?: boolean
  }

export interface UseClientPrintReturnType {
  printDocument: (variables: UseClientPrintVariables) => Promise<void>
  isLoading: boolean
  hasError: boolean
}

export const useClientPrint = (
  options?: UseClientPrintProperties,
): UseClientPrintReturnType => {
  const { isPublic, ...restOptions } = options || {}
  const endpoint = isPublic ? "document/anonExport" : "document/export"

  const { mutate, data, isLoading, hasError } = usePost<
    any,
    UseClientPrintVariables
  >(endpoint, { ...restOptions, isPublic })

  const [currentVariables, setCurrentVariables] =
    useState<UseClientPrintVariables | null>(null)

  const printDocument = async (variables: UseClientPrintVariables) => {
    setCurrentVariables(variables)

    await mutate({
      ...variables,
    })
  }

  useEffect(() => {
    const downloadPDF = async () => {
      if (data?.success && currentVariables) {
        const blob = await pdf(
          <PDFDocument values={currentVariables.documentData} />,
        ).toBlob()
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.download = `${
          currentVariables.documentData?.documentNumber ||
          currentVariables.documentData?.name ||
          "Document"
        }.pdf`
        link.style.display = "none"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }
    }
    downloadPDF()
  }, [data])

  return { printDocument, isLoading, hasError }
}

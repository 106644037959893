import "./DangerZone.scss"

import type { ReactNode } from "react"
import { ConfirmButton } from "sections/user/pages/Components/ConfirmButton/ConfirmButton"
import { IconDeleteBin2Line } from "shared/assets/icons"
import { Button } from "shared/components/Button/Button"
import { useAuth } from "shared/services/hooks/auth/useAuth"
import { useDeleteUser } from "shared/services/hooks/user/useDeleteUser"

export function DangerZone(): ReactNode {
  const { signOut } = useAuth()

  const { remove: deleteUser, isLoading: isDeletingUser } = useDeleteUser()

  const handleDelete = async () => {
    await deleteUser()
    signOut()
  }

  const handleSignOut = (): void => {
    signOut()
  }

  return (
    <div className="DangerZone">
      <h2>Danger Zone</h2>
      <div className="DangerZone__Logout">
        <Button variant="outlined" onClick={handleSignOut}>
          Se déconnecter
        </Button>
      </div>
      <div className="DangerZone__DeleteAccount">
        <p>
          Supprimer votre compte mettra automatiquement fin à votre abonnement
          et supprimera l'intégralité de vos données, factures, devis et autres
          documents.
        </p>
        <ConfirmButton
          color="danger"
          isDisabled={isDeletingUser}
          icon={<IconDeleteBin2Line />}
          iconPosition="left"
          onClick={handleDelete}
        >
          Supprimer mon compte
        </ConfirmButton>
      </div>
    </div>
  )
}

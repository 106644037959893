import { pdf } from "@react-pdf/renderer"
import { useEffect } from "react"
import { PDFDocument } from "sections/documents/PDFDocument/PDFDocument"
import type { UsePostProperties } from "shared/services/interfaces"

import { usePost } from "../core/usePost"

interface UseClientPrintFromIdVariables {
  documentId?: string
}

export type UseClientPrintFromIdProperties =
  UsePostProperties<UseClientPrintFromIdVariables>

export interface UseClientPrintFromIdReturnType {
  printDocument: (variables: UseClientPrintFromIdVariables) => Promise<void>
  isLoading: boolean
  hasError: boolean
}

export const useClientPrintFromId = (
  options?: UseClientPrintFromIdProperties,
): UseClientPrintFromIdReturnType => {
  const { mutate, data, isLoading, hasError } = usePost<
    any,
    UseClientPrintFromIdVariables
  >("document/exportExisting", { ...options })

  const printDocument = async (variables: UseClientPrintFromIdVariables) => {
    await mutate({
      ...variables,
    })
  }

  useEffect(() => {
    const downloadPDF = async () => {
      if (data?.success && data?.documentData) {
        const blob = await pdf(
          <PDFDocument values={data.documentData} />,
        ).toBlob()
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.download = `${
          data.documentData?.documentNumber ||
          data.documentData?.name ||
          "Document"
        }.pdf`
        link.style.display = "none"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }
    }
    downloadPDF()
  }, [data])

  return { printDocument, isLoading, hasError }
}

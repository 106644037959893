import "./UserBlock.scss"

import { routes } from "app/router/routes"
import classNames from "classnames"
import type { ReactElement } from "react"
import { NavLink } from "react-router-dom"
import { IconCoinsLine } from "shared/assets/icons"
import { Icon } from "shared/components/Icon/Icon"
import type { UserData, UserPlan } from "shared/services/interfaces"

import { Button } from "../Button/Button"

interface UserBlockProps {
  givenName?: UserData["givenName"]
  credits: UserPlan["credits"]
  picture?: UserData["picture"]
}

export function UserBlock({
  givenName,
  credits,
  picture,
}: UserBlockProps): ReactElement {
  return (
    <div className="UserBlock">
      <NavLink to={routes.plans.index} className="UserBlock__credits">
        <Icon icon={<IconCoinsLine />} />
        {credits !== "unlimited" ? `${credits} Crédits` : "Illimités"}
      </NavLink>

      <NavLink
        to={routes.user.index}
        className={({ isActive }) =>
          classNames("UserBlock__user", {
            "UserBlock__user--active": isActive,
          })
        }
      >
        {/* TODO Move logout in dropdown */}

        {picture && (
          <img
            className="UserBlock__user__picture"
            referrerPolicy="no-referrer"
            src={picture}
          />
        )}
        {givenName ? (
          <button className="UserBlock__user__name">{givenName}</button>
        ) : (
          <Button variant="link">Mon Compte</Button>
        )}
      </NavLink>
    </div>
  )
}

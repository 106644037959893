import type {
  ReturnDocuments,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"

import { useGet } from "../core/useGet"

export function useGetDocuments(
  options: UseGetProperties = {},
): UseGetReturnType<ReturnDocuments> {
  return useGet(`documents`, options)
}

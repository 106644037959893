export const routeToPath = (
  route: string,
  parameters: Record<string, string>,
): string => {
  let result = route
  Object.entries(parameters).forEach(([key, value]) => {
    result = result.replace(`:${key}`, value)
  })
  return result
}

export const routes = {
  auth: {
    login: "/login",
    signup: "/auth/signup",
    authenticated: "authenticated",
  },
  home: {
    index: "/",
  },
  user: {
    index: "/user/profile",
  },
  plans: {
    index: "/plans",
  },
  clients: {
    index: "/clients",
  },
  documents: {
    history: "/documents/history",
    create: "/documents/create",
    document: "/documents/:documentId",
    publicCreate: "/documents/publicCreate",
  },
  // demo: {
  //   lookup: "/demo/lookup",
  //   davinci: "/demo/davinci",
  //   clients: "/demo/clients",
  //   feedbacks: "/demo/feedbacks",
  // },
}

import "./DocumentFooter.scss"

import type { ReactElement } from "react"
// import logoDefault from "shared/assets/illustrations/logo.svg"
// import { Illustration } from "shared/components/Illustration/Illustration"
import {
  PaymentModeEnum,
  type ReturnDocument,
} from "shared/services/interfaces"

export interface DocumentFooterProps
  extends Partial<
    Pick<
      ReturnDocument,
      "latePenalties" | "paymentMode" | "note" | "userCompany"
    >
  > {
  // hasLogo: boolean
}

export function DocumentFooter({
  latePenalties,
  paymentMode,
  note,
  userCompany,
}: // hasLogo,
DocumentFooterProps): ReactElement {
  return (
    <footer className="DocumentFooter">
      {note && (
        <div className="DocumentFooter__Note">
          <p>{note}</p>
        </div>
      )}

      <div className="DocumentFooter__Pay">
        {paymentMode && (
          <div className="DocumentFooter__Pay__Payment">
            <div className="DocumentFooter__Pay__Payment__Details">
              <h4>Mode de règlement :</h4>
              <p>{paymentMode.name}</p>
            </div>

            <div className="DocumentFooter__Pay__Payment__Details">
              {paymentMode.name === PaymentModeEnum.BankTransfer && (
                <>
                  <h4>Coordonnées bancaires : </h4>
                  <p>IBAN : {paymentMode.iban}</p>
                  <p>BIC / Swift Code: {paymentMode.bic}</p>
                </>
              )}
              {paymentMode.name === PaymentModeEnum.Other && (
                <>
                  <p>Details</p>
                  <p>{paymentMode.details}</p>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="DocumentFooter__Penalties">
        {latePenalties?.latePenaltyRate ? (
          <p>Taux de pénalité : {latePenalties.latePenaltyRate.toFixed(2)} %</p>
        ) : null}
        <p>
          Indemnité forfaitaire pour frais de recouvrement en cas de retard de
          paiement : 40,00 €.
        </p>
      </div>

      {!userCompany?.hasRcs ? (
        <div className="DocumentFooter__RCS">
          <p>
            Dispensé d’immatriculation au registre du commerce et des sociétés
            (RCS) et au répertoire des métiers (RM)
          </p>
        </div>
      ) : null}

      {/* {hasLogo && (
        <div className="DocumentFooter__Signature">
          <p>Ce document a été rédigé et généré via Stabbl en 473ms.</p>
          <Illustration
            className="DocumentFooter__Signature__Logo"
            svg={logoDefault}
          />
        </div>
      )} */}
    </footer>
  )
}

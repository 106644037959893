import { RequireAuth } from "app/router/RequireAuth"
import { routes } from "app/router/routes"
import type { ReactElement } from "react"
import { Route, Routes } from "react-router-dom"

import { CreateDocumentPage } from "./pages/CreateDocumentPage/CreateDocumentPage"
import { CreateDocumentPagePublic } from "./pages/CreateDocumentPagePublic/CreateDocumentPagePublic"
import { DocumentPage } from "./pages/DocumentPage/DocumentPage"
import { DocumentsHistoryPage } from "./pages/DocumentsHistoryPage/DocumentsHistoryPage"

export function DocumentsRouter(): ReactElement {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route
          path={routes.documents.history}
          element={<DocumentsHistoryPage />}
        />
        <Route path={routes.documents.document} element={<DocumentPage />} />
        <Route
          path={routes.documents.create}
          element={<CreateDocumentPage />}
        />
      </Route>

      <Route
        path={routes.documents.publicCreate}
        element={<CreateDocumentPagePublic />}
      />
    </Routes>
  )
}

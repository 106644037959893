function formatSiretOrSiren(input?: string | null): string {
  if (input == null || typeof input !== "string") {
    return ""
  }

  const cleanedInput = input.replace(/\s+/g, "") // Enlève les espaces

  if (cleanedInput.length === 9) {
    // SIREN
    return cleanedInput.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")
  } else if (cleanedInput.length === 14) {
    // SIRET
    return cleanedInput.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4")
  } else {
    return cleanedInput
  }
}
export { formatSiretOrSiren }

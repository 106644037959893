import type { RefObject } from "react"
import { useCallback, useLayoutEffect, useState } from "react"

interface ComponentSize {
  width: number
  height: number
}

const defaultSize: ComponentSize = {
  width: 0,
  height: 0,
}

function getSize<E extends HTMLElement>(el: E) {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  }
}

export function useComponentSize<E extends HTMLElement>(
  ref: RefObject<E | null>,
): ComponentSize {
  const [componentSize, setComponentSize] = useState(
    ref.current ? getSize<E>(ref.current) : defaultSize,
  )

  const handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current))
      }
    },
    [ref],
  )

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    handleResize()

    if (typeof ResizeObserver === "function") {
      const resizeObserver = new ResizeObserver(() => {
        handleResize()
      })

      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect()
      }
    } else {
      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [ref.current])

  return componentSize
}

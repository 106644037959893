import type {
  PaymentModeOther,
  PaymentModeTransfer,
} from "shared/services/interfaces"

export function saveLocalPaymentMode(
  paymentMode: PaymentModeTransfer | PaymentModeOther,
): void {
  return localStorage.setItem("paymentMode", JSON.stringify(paymentMode))
}

import "index.scss"
import "react-toastify/dist/ReactToastify.css"
import "swiper/css"

import React from "react"
import { createRoot } from "react-dom/client"

import App from "./app/App"
import reportWebVitals from "./reportWebVitals"

const root = document.getElementById("root")

if (root) {
  const appRoot = createRoot(root)
  appRoot.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { Image, Text, View } from "@react-pdf/renderer"
import type { ReactElement } from "react"
import { PostalAddress } from "shared/components/PostalAddress/PostalAddress"
import type { ReturnDocument } from "shared/services/interfaces"
import { formatDateToDisplay } from "shared/utils/formatDateToDisplay"
import { formatSiretOrSiren } from "shared/utils/formatSirenSiret"

import { styles } from "./DocumentHeaderStyle"

export interface DocumentHeaderProps
  extends Partial<
    Pick<
      ReturnDocument,
      | "documentNumber"
      | "type"
      | "issueDate"
      | "dueDate"
      | "internalReference"
      | "clientCompany"
      | "userCompany"
      | "name"
    >
  > {}

export function DocumentHeader({
  documentNumber,
  type,
  issueDate,
  dueDate,
  internalReference,
  clientCompany,
  userCompany,
  name,
}: DocumentHeaderProps): ReactElement {
  return (
    <View style={styles.DocumentHeader}>
      {/* Header Left */}
      <View style={styles.DocumentHeaderLeft}>
        {/* Logo */}
        {userCompany?.logo ? (
          <View>
            <Image
              style={styles.DocumentHeaderUserCompanyLogo}
              src={`${userCompany.logo}?${Date.now()}`}
            />
          </View>
        ) : null}

        {/* Company name  */}
        <View>
          <Text style={styles.DocumentHeaderUserCompanyName}>
            {userCompany?.name}
          </Text>

          {/* Postal address */}
          {userCompany?.postalAddress && (
            <View>
              <Text>
                <PostalAddress address={userCompany.postalAddress} />
              </Text>
            </View>
          )}
        </View>

        {/* Phone and email */}
        <View>
          <Text>{userCompany?.email}</Text>
          <Text>{userCompany?.phone}</Text>
        </View>

        {/* SIRET, RCS & TVA */}
        <View>
          {userCompany?.siret && (
            <Text>SIRET : {formatSiretOrSiren(userCompany?.siret)}</Text>
          )}
          {userCompany?.rcsRegistration && (
            <Text>RCS : {userCompany?.rcsRegistration}</Text>
          )}
          {userCompany?.applyVAT && (
            <Text>N° TVA : {userCompany?.vatNumber}</Text>
          )}
        </View>

        {name && <Text style={styles.DocumentHeaderName}>{name}</Text>}
      </View>
      {/* Header Right  */}
      <View style={styles.DocumentHeaderRight}>
        <Text style={styles.DocumentType}>{type}</Text>

        <View style={styles.DocumentMeta}>
          <View style={styles.DocumentMetaLine}>
            <Text style={styles.DocumentMetaLabel}>Numéro</Text>
            <Text style={styles.DocumentMetaValue}>{documentNumber}</Text>
          </View>

          {internalReference && (
            <View style={styles.DocumentMetaLine}>
              <Text style={styles.DocumentMetaLabel}>Référence</Text>
              <Text style={styles.DocumentMetaValue}>{internalReference}</Text>
            </View>
          )}

          {issueDate && (
            <View style={styles.DocumentMetaLine}>
              <Text style={styles.DocumentMetaLabel}>Date d'émission</Text>
              <Text style={styles.DocumentMetaValue}>
                {formatDateToDisplay(issueDate)}
              </Text>
            </View>
          )}

          {dueDate && (
            <View style={styles.DocumentMetaLine}>
              <Text style={styles.DocumentMetaLabel}>Date d'échéance</Text>
              <Text style={styles.DocumentMetaValue}>
                {formatDateToDisplay(dueDate)}
              </Text>
            </View>
          )}

          {clientCompany && (
            <View style={styles.DocumentHeaderClient}>
              {clientCompany.name && (
                <Text style={styles.DocumentHeaderClientName}>
                  {clientCompany.name}
                </Text>
              )}
              <Text>
                <PostalAddress address={clientCompany.postalAddress} />
              </Text>
              <View>
                <Text>{clientCompany.contact}</Text>
                <Text>{clientCompany.email}</Text>
              </View>
              <View>
                <Text>SIRET : {formatSiretOrSiren(clientCompany.siret)}</Text>
                <Text>N° TVA : {clientCompany.vatNumber}</Text>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

import type { Client } from "../interfaces"

export const clientMock: Client = {
  id: "1",
  email: "client@example.com",
  phone: "1234567890",
  contact: "John Doe",
  name: "Rockstar Games",
  siret: "123456789",
  postalAddress: "1 rue de la Paix, 75000 Paris, France",
  vatNumber: "FR123456789",
  logo: "https://logo.clearbit.com/rockstargames.com",
}

import { StyleSheet } from "@react-pdf/renderer"

export const styles = StyleSheet.create({
  PageNumber: {
    color: "#545454",
    paddingTop: 8,
  },
  DocumentFooter: {
    paddingTop: 16,
    gap: 2,
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    textAlign: "center",
    alignItems: "center",
    borderTop: "1pt solid #cccccc",
    color: "#545454",
    marginTop: "auto",
  },
  DocumentFooterPayment: {
    color: "#000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  DocumentFooterPaymentRow: {
    display: "flex",
    flexDirection: "row",
  },
  DocumentFooterPaymentRowLabel: {
    fontWeight: "medium",
  },
})

import type { ReactElement } from "react"
import { Field } from "react-final-form"
import { Input } from "shared/components/Input/Input"

interface NameFieldProps {
  name: string
  value?: string
}

export function NameField({ name, value }: NameFieldProps): ReactElement {
  return (
    <Field name={name}>
      {props => (
        <Input
          label="Nom de la prestation"
          value={props.input.value || value}
          name={props.input.name}
          onChange={props.input.onChange}
        />
      )}
    </Field>
  )
}

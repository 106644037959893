export function formatPrice(value: number | null): string {
  if (typeof value !== "number" || isNaN(value)) {
    return "-"
  }

  const amount = value / 100
  // const amount = value
  const formattedPrice = amount.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return formattedPrice
}

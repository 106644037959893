import "./BillingModeSwitch.scss"

import type { ReactNode } from "react"
import type { BillingMode } from "shared/services/interfaces"

import { BillingModeSwitchAction } from "./BillingModeSwitchAction"

export interface BillingModeSwitchProps {
  billingMode: BillingMode
  onChange: (value: BillingMode) => void
}

export function BillingModeSwitch({
  billingMode,
  onChange,
}: BillingModeSwitchProps): ReactNode {
  return (
    <div className="BillingModeSwitch">
      <BillingModeSwitchAction
        value="month"
        billingMode={billingMode}
        onChange={onChange}
      >
        Mensuel
      </BillingModeSwitchAction>
      <BillingModeSwitchAction
        hasDiscount
        value="year"
        billingMode={billingMode}
        onChange={onChange}
      >
        Annuel
      </BillingModeSwitchAction>
    </div>
  )
}

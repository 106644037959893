import type {
  AnonUserData,
  UseGetProperties,
  UseGetReturnType,
} from "shared/services/interfaces"

import { useGet } from "../core/useGet"

export function useGetPublicUserData(
  options: UseGetProperties = {},
): UseGetReturnType<AnonUserData> {
  return useGet("anonymousUser", { ...options, isPublic: true })
}

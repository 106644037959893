import "./TogglableInput.scss"

import { type ReactElement, useEffect, useState } from "react"
import type { FieldInputProps } from "react-final-form"
import { Field, useForm } from "react-final-form"
import { Input } from "shared/components/Input/Input"
import { LabelledCheckbox } from "shared/components/LabelledCheckbox/LabelledCheckbox"

interface TogglableInputProps {
  name: string
  inputLabel: string
  checkboxLabel: string
  checkboxName: string
  value?: string
}

export function TogglableInput({
  name,
  inputLabel,
  checkboxName,
  checkboxLabel,
  value,
}: TogglableInputProps): ReactElement {
  const [isChecked, setIsChecked] = useState(false)
  const { getFieldState } = useForm()

  const handleCheckboxChange = (input: FieldInputProps<string>) => {
    input.onChange(!input.checked)
    setIsChecked(!input.checked)
  }

  useEffect(() => {
    const checkboxInitialState = getFieldState(checkboxName)?.initial
    setIsChecked(checkboxInitialState)
  }, [getFieldState, checkboxName])

  return (
    <div className="TogglableInput__input">
      <Field
        name={checkboxName}
        type="checkbox"
        render={({ input }) => (
          <LabelledCheckbox
            isChecked={input.checked || false}
            name={input.name}
            onChange={() => handleCheckboxChange(input)}
          >
            {checkboxLabel}
          </LabelledCheckbox>
        )}
      />

      {isChecked ? (
        <Field name={name}>
          {({ input }) => (
            <Input
              isDisabled={!isChecked}
              label={inputLabel}
              value={input.value || value}
              name={input.name}
              onChange={input.onChange}
            />
          )}
        </Field>
      ) : null}
    </div>
  )
}

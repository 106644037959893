function formatDateToDisplay(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  }
  const date = new Date(dateString)
  return date.toLocaleDateString("fr-FR", options)
}

export { formatDateToDisplay }

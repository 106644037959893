import type { ReactNode } from "react"
import { BlockWithRequestsBatch } from "shared/components/BlockWithRequestsBatch/BlockWithRequestsBatch"
import { LoadingPage } from "shared/components/LoadingPage/LoadingPage"
import { useBatchAppError } from "shared/hooks/useBatchAppError"
import { useGetDocument } from "shared/services/hooks/documents/useGetDocument"
import type { Clients, UserData } from "shared/services/interfaces"

import { CreateDocumentPageView } from "./CreateDocumentPageView"

export interface CreateDocumentWithDocumentProps {
  user: UserData
  clients: Clients
  documentId: string
}

export function CreateDocumentWithDocument({
  user,
  clients,
  documentId,
}: CreateDocumentWithDocumentProps): ReactNode {
  const {
    data: documentData,
    isLoading: isDocumentLoading,
    hasError: hasDocumentError,
    refetch: retryDocument,
  } = useGetDocument({
    params: { documentId: documentId },
  })

  const requestsResultsBatch = useBatchAppError({
    data: documentData,
    hasError: hasDocumentError,
    retry: retryDocument,
  })

  return (
    <BlockWithRequestsBatch
      renderWhenLoading={() => <LoadingPage />}
      isLoading={isDocumentLoading}
      requestsResultsBatch={requestsResultsBatch}
      renderWhenData={document => (
        <CreateDocumentPageView
          user={user}
          clients={clients}
          existingData={document}
        />
      )}
    />
  )
}
